const BASE_API = process.env.REACT_APP_ROUTE_API;

const token = localStorage.getItem('token');

export default {
  getMidiaKitData: async (profile_id) => {
    try {
      const req = await fetch(`${BASE_API}midia-kit/` + profile_id + `?token=` + token, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
      });
      const json = await req.json();
      return json;
    } catch (error) {
      console.log(error);
    }
  },
  storeMidiaKit: async(dataMidia) => {
    dataMidia.token = token;
    try {
      const req = await fetch(`${BASE_API}midia-kit`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataMidia)
      });
      const json = await req.json();
      return json;
    } catch (error) {
      console.log(error);
    }
  },
  storeImageMidiaKit: async(dataImage) => {
    dataImage.token = token;
    try {
      const req = await fetch(`${BASE_API}midia-kit-images`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataImage)
      });
      const json = await req.json();
      return json;
    } catch (error) {
      console.log(error);
    }
  },
  storeBrandsMidiaKit: async (dataBrand) => {
    dataBrand.token = token;
    try {
      const req = await fetch(`${BASE_API}midia-kit-brands`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataBrand)
      });
      const json = await req.json();
      return json;
    } catch (error) {
      console.log(error);
    }
  },
  storePartnersMidiaKit: async (dataPartner) => {
    dataPartner.token = token;
    try {
      const req = await fetch(`${BASE_API}midia-kit-partners`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataPartner)
      });
      const json = await req.json();
      return json;
    } catch (error) {
      console.log(error);
    }
  }
};