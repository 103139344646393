import React from 'react';
import { Card, Col, Row, CardText } from 'reactstrap';
import logo200Image from '../assets/img/logo/logo_200.png';

export default function AuthPage() {
  
  return (
    <Row
      style={{
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Col md={8} lg={8}>
        <Card body>
            <div className="text-center pb-4">
              <img
                src={logo200Image}
                className="rounded"
                style={{ width: 60, height: 60, cursor: 'pointer' }}
                alt="logo"
              />
            </div>
            <CardText>
              <h3 class="text-center">TERMOS DE SERVIÇO</h3>

              <h3>VISÃO GERAL</h3>

              <p>Esse site é operado pela Matilha Brasil. Em todo o site, os termos “nós”, “nos” e “nosso” se referem à Matilha Brasil.  Matilha Brasil proporciona esse site, incluindo todas as informações, ferramentas e serviços disponíveis deste site para você, o usuário, com a condição da sua aceitação de todos os termos, condições, políticas e avisos declarados aqui.</p>

              <p>Ao visitar nosso site e/ou contratar e/ou ser contratado para um serviço e/ou usufruir de nossas funcionalidades aqui oferecidas, você está utilizando nossos “Serviços”. Consequentemente, você  concorda com os seguintes termos e condições (“Termos de serviço”, “Termos”), incluindo os termos e condições e políticas adicionais mencionados neste documento e/ou disponíveis por hyperlink. Esses Termos de serviço se aplicam a todos os usuários do site, incluindo, sem limitação, os usuários que são navegadores, influenciadores, profissionais de mercado, contratantes e/ou contribuidores de conteúdo.</p>

              <p>Por favor, leia esses Termos de serviço cuidadosamente antes de acessar ou utilizar o nosso site. Ao acessar ou usar qualquer parte do site, você concorda com os Termos de serviço. Se você não concorda com todos os termos e condições desse acordo, então você não pode acessar o site, usufruir de suas funcionalidades ou usar quaisquer serviços. Se esses Termos de serviço são considerados uma oferta, a aceitação é expressamente limitada a esses Termos de serviço.</p>

              <p>Quaisquer novos recursos ou ferramentas que forem adicionados à essa plataforma também devem estar sujeitos aos Termos de serviço. Você pode revisar a versão mais atual dos Termos de serviço quando quiser nesta página. Reservamos o direito de atualizar, alterar ou trocar qualquer parte destes Termos de serviço ao publicar atualizações e/ou alterações no nosso site. É sua responsabilidade verificar as alterações feitas nesta página periodicamente. Seu uso contínuo ou acesso ao site após a publicação de quaisquer alterações constitui aceitação de tais alterações.</p>

              <br />

              <center><p><strong>SEÇÃO 1 - TERMOS DO MARKETPLACE VIRTUAL</strong></p></center>

              <p>Ao concordar com os Termos de serviço, você confirma que você é maior de idade em seu estado ou província de residência.</p>

              <p>Você não deve usar nossos produtos E SERVIÇOS para qualquer fim ilegal ou não autorizado. Você também não pode, ao usufruir deste Serviço, violar quaisquer leis em sua jurisdição (incluindo, mas não limitado, a leis de direitos autorais).</p>

              <p>Você não deve transmitir nenhum vírus ou qualquer código de natureza destrutiva.</p>

              <p>Violar qualquer um dos Termos tem como consequência a rescisão imediata dos seus serviços.</p>

              <br />

              <center><p><strong>SEÇÃO 2 - CONDIÇÕES GERAIS</strong></p></center>

              <p>Reservamos o direito de recusar o serviço a qualquer pessoa por qualquer motivo a qualquer momento.</p>

              <p>Você entende que o seu conteúdo, pode ser transferido sem criptografia e pode: (a) ser transmitido por várias redes; e (b) sofrer alterações para se adaptar e se adequar às exigências técnicas de conexão de redes ou dispositivos. </p>

              <p>Você concorda em não reproduzir, duplicar, copiar, vender, revender ou explorar qualquer parte do Serviço, uso do Serviço, acesso ao Serviço, ou qualquer contato no site através do qual o serviço é fornecido, sem nossa permissão expressa por escrito.</p>

              <p>Os títulos usados neste acordo são incluídos apenas por conveniência e não limitam ou afetam os Termos.</p>

              <br />

              <center><p><strong>SEÇÃO 3 - PRECISÃO, INTEGRIDADE E ATUALIZAÇÃO DAS INFORMAÇÕES</strong></p></center>

              <p>Não somos responsáveis por informações disponibilizadas neste site que não sejam precisas, completas ou atuais. O material desse site é fornecido apenas para fins informativos e não deve ser usado como a única base para tomar decisões sem consultar fontes de informações primárias, mais precisas, mais completas ou mais atuais. Qualquer utilização do material desse site é por sua conta e risco.</p>

              <p>Esse site pode conter certas informações históricas. As informações históricas podem não ser atuais e são fornecidas apenas para sua referência. Reservamos o direito de modificar o conteúdo desse site a qualquer momento, mas nós não temos obrigação de atualizar nenhuma informação em nosso site. Você concorda que é de sua responsabilidade monitorar alterações no nosso site.</p>

              <br />

              <center><p><strong>SEÇÃO 4 - MODIFICAÇÕES DO SERVIÇO E PREÇOS</strong></p></center>

              <p>Os preços dos nossos produtos e serviços estão sujeitos a alterações sem notificação.</p>

              <p>Reservamos o direito de, a qualquer momento, modificar ou descontinuar o Serviço (ou qualquer parte ou conteúdo do mesmo) sem notificação em qualquer momento.</p>

              <p>Não nos responsabilizamos por você ou por qualquer terceiro por qualquer modificação, alteração de preço, suspensão ou descontinuação do Serviço.</p>

              <br />

              <center><p><strong>SEÇÃO 5 - PRODUTOS OU SERVIÇOS (caso aplicável)</strong></p></center>

              <p>Reservamos o direito, mas não somos obrigados a limitar as vendas de nossos produtos ou serviços para qualquer pessoa, região geográfica ou jurisdição. Podemos exercer esse direito conforme o caso. Reservamos o direito de limitar as quantidades de quaisquer produtos ou serviços que oferecemos. Todas as descrições de produtos ou preços de produtos são sujeitos a alteração a qualquer momento sem notificação, a nosso exclusivo critério. Reservamos o direito de descontinuar qualquer produto a qualquer momento. Qualquer oferta feita por qualquer produto ou serviço nesse site é nula onde for proibido por lei.</p>

              <p>Não garantimos que a qualidade de quaisquer produtos, serviços, informações ou outros materiais comprados ou obtidos por você vão atender às suas expectativas, ou que quaisquer erros no Serviço serão corrigidos.</p>

              <br />

              <p><strong>a. APLICAÇÃO É APENAS UM ESPAÇO.</strong></p>

              <p>A Plataforma de Mídia Kit da Matilha Brasil é uma interface online e dinâmica para elaboração do Currículo de Influenciador Digital de uso exclusivo dos membros ativos do Clube de Assinatura Academia da Matilha, aqui chamados Usuários. Estes serviços específicos solicitados pelos Usuários, que devem ser realizados pelos próprios, são denominados adiante, “Mídia Kit”.  Em conformidade com os termos deste Acordo, a Matilha Brasil oferece essa ferramenta e funcionalidade de forma gratuita para os membros ativos, incluindo o hosting e a manutenção do Site da Matilha Brasil, e permite a divulgação do currículo do influenciador de forma fácil, leve e dinâmica pelos Usuários em todo e qualquer canal.</p>

              <br />

              <p><strong>b. Entregas do Usuário.</strong></p>

              <p>A Empresa não se responsabilidade pelas informações fornecidas pelo Usuário em sua página de Mídia Kit, seja ela em dados, imagens, histórias, cases e qualquer outra informação que compõe seu conteúdo aqui exibido, inclusive sobre responsabilidade do usuário também a responsabilidade sobre manipulação, inflação e uso de qualquer meio, dinâmica ou book para modificar os números fornecidos pelas plataformas de redes sociais, aqui indicados via API oficial da rede.</p>

              <br />

              <p><strong>c. Produto do Trabalho.</strong></p>

              <p>A Matilha Brasil não se responsabiliza pela qualidade do trabalho e nem pelos resultados apresentados pelo criador de conteúdo à empresa contratante por meio deste mídia kit. Sendo assim, todo o trabalho, negociação, produção e resultado é de única e inteira responsabilidade do criador de conteúdo autor do Mídia Kit.</p>

              <br />

              <p><strong>e. VETO DE USUÁRIOS.</strong></p>

              <p>A Matilha Brasil pode realizar verificações de antecedentes dos Especialistas, a Matilha Brasil não pode confirmar que cada Especialista é quem declara ser e, portanto, não pode assumir e nem assume nenhuma responsabilidade pela precisão ou veracidade da identidade, pela informação da verificação de antecedentes ou pela informação proporcionada através do Site da Matilha Brasil.</p>

              <p>Ao utilizar a aplicação da Matilha Brasil, você aceita liberar a Matilha Brasil de toda a responsabilidade ou dano que possa surgir a partir dos serviços profissionais. A Matilha Brasil não é responsável pela conduta, seja online ou não, de nenhum usuário, incluindo os provedores externos, e não será responsável por nenhuma reclamação, lesão ou dano que surja em relação aos serviços autônomos.</p>
            </CardText>

        </Card>
      </Col>
    </Row>
  );
  
}









