import { useState, useEffect } from 'react';
import ApiUser from '../services/users/userServices'
import history from '../history';
import { toast } from 'react-toastify';

export default function useAuth() {
  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState('');
  const [loading, setLoading] = useState(true);
  

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token && token.length > 0) {
      setAuthenticated(true);
    }
 
    setLoading(false);
  }, []);

  async function handleLogin(email, password) {
    const res = await ApiUser.login(email, password);
    if (res.error) {     
      toast.error('🦄 Hummmm! Usuário ou senha inválidos!', {theme: "colored"});
    } else {
      
      toast.success('🦄 Já estamos trabalhando no seu acesso!', { theme: "colored" });
      
      const me = await ApiUser.me(res.access_token);
      
      toast.error(me, { theme: "colored" });
      
      if(me.types === null){
        toast.error('🦄 Hummmm! Seu usuário ainda não possui um perfil de influenciador! Contate o administrador do sistema.', { theme: "colored" });
        setAuthenticated(false);
      } else if(me.id){
        localStorage.setItem('token', res.access_token)
        localStorage.setItem('user', JSON.stringify(me))
        setAuthenticated(true);
        setUser(me);
        history.go('/') 
      }else{
        toast.error('🦄 Hummmm! Usuário ou senha inválidos!', { theme: "colored" });
        setAuthenticated(false);
      }
            
    }

  }

  function handleLogout() {
    setAuthenticated(false);
    localStorage.removeItem('token');
    history.push('/login');
  }

  return { authenticated, loading, handleLogin, handleLogout, user };
}