
import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import {
  Button
} from 'reactstrap';

const FacebookButonLogin = ({
  getData,
  ...restProps
}) => {

  const responseFacebook = async (response) => {
    getData(response);
  }

  return (        
    <FacebookLogin
      appId="1077570872987816"
      size="small"
      callback={responseFacebook}
      disableMobileRedirect={true}
      scope="email,pages_show_list,instagram_basic,pages_read_engagement,public_profile,instagram_manage_insights"
      render={renderProps => (
        <Button color="outline-primary" onClick={renderProps.onClick}>
          Atualizar Token
        </Button>
      )}
    />
  );
}

export default FacebookButonLogin;
