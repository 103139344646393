const BASE_API = process.env.REACT_APP_ROUTE_API;
const token = localStorage.getItem('token');
export default {
  getStates: async () => {
    try {
      const req = await fetch(`${BASE_API}states?token=` + token, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      });
      return await req.json();
    } catch (error) {
      return(error);
    }
  },
  getCitiesByState: async (state) => {
    try {
      const req = await fetch(`${BASE_API}states/`+state+`?token=` + token, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
      });
      const json = await req.json();
      return json;
    } catch (error) {
      return error;
    }
  }
 
};