const BASE_API = process.env.REACT_APP_ROUTE_API;
const FACEBOOK_ID_APP = process.env.REACT_APP_ID_FACEBOOK;
const FACEBOOK_SECRET_APP = process.env.REACT_APP_SECRET_FACEBOOK;
const FACEBOOK_VERSION_API = process.env.REACT_APP_FACEBOOK_VERSION_API;
const FACEBOOK_URL_API = process.env.REACT_APP_FACEBOOK_URL_API;
const token = localStorage.getItem('token');
const user = JSON.parse(localStorage.getItem('user'));

export default {
  refreshToken: async (token) => {
    try {
      const req = await fetch(`${FACEBOOK_URL_API}/${FACEBOOK_VERSION_API}/oauth/access_token?grant_type=fb_exchange_token&client_id=${FACEBOOK_ID_APP}&client_secret=${FACEBOOK_SECRET_APP}&fb_exchange_token=${token}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
      });
      const json = await req.json();
      return json;      

    } catch (error) {
      console.log(error);
    }
  },
  getInstagramBusinessAccounts: async (userId, token) => {
    try {
      const req = await fetch(`${FACEBOOK_URL_API}/${FACEBOOK_VERSION_API}/me/accounts?fields=instagram_business_account%7Bname%2Cusername%2Cprofile_picture_url%7D%2Caccess_token%2Cname%2Cpicture%7Burl%7D&access_token=${token}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
      });
      const json = await req.json();
      return json;

    } catch (error) {
      console.log(error);
    }
  },
  getSocialNetworks: async() => {
    try {
      const req = await (await fetch(`${BASE_API}user-social-network/` + user.id + `?token=` + token, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
      })).json()

        
      const data = Promise.all(
        req.map(async (item) => await (await fetch(`${FACEBOOK_URL_API}/${FACEBOOK_VERSION_API}/${item.social_network_id}?fields=biography&access_token=${item.token}`, {
          method: "GET",
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
        })).json())
      )

      return data

    } catch (error) {
      console.log(error);
    }
  },
  getInstagramMidias: async (userId, token) => {
    try {
      let url = `${FACEBOOK_URL_API}/${FACEBOOK_VERSION_API}/${userId}/media`
          url +=`?fields=comments_count,like_count,media_url,thumbnail_url,media_type,caption,shortcode`
          url += `&limit=15`
          url += `&access_token=${token}`
      const req = await fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
      });
      const json = await req.json();
      return json;

    } catch (error) {
      console.log(error);
    }
  },
  showInstagramMidias: async (url) => {
    try {
      const req = await fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
      });
      const json = await req.json();
      return json;

    } catch (error) {
      console.log(error);
    }
  },
  getInstagramBusinessAccountInfo: async (account,userId, token) => {
    try {
      const req = await fetch(`${FACEBOOK_URL_API}/${FACEBOOK_VERSION_API}/${userId}/?fields=business_discovery.username(${account}){followers_count,media_count,profile_picture_url,id,ig_id,name,username}&access_token=${token}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
      });
      const json = await req.json();
      return json;

    } catch (error) {
      console.log(error);
    }
  },
};