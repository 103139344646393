
import React, { useState, useEffect } from 'react';

import {
  Card,
  CardBody,
  CardText,
  Col,
  Row
} from 'reactstrap';

const InstagramProfilePageList = ({
  pages,
  getProfile,
  ...restProps
}) => {

  const profiles = pages.data.map((key) => (key)).filter((key) => key.instagram_business_account !== undefined)
  const responseData = (data) => {
    getProfile(data);
  }

  return (
    <>
      <Row className="mb-3">
        <Col md={12} sm={12} xs={12}>
          <Card>
            <CardBody>
              <CardText>
                <small>
                  Para selecionar o perfil desejado basta clicar sobre ele e deixe o resto com a gente 😉
                </small>
              </CardText>
            </CardBody>
          </Card>              
        </Col>
      </Row>
      <hr/>
      
      {
        profiles.map((item) =>
          <Row className="mt-3" key={item.instagram_business_account.id}>
            <Col md={12} sm={12} xs={12}>
              <Card onClick={() => responseData(item)} className="can-click">
                <CardBody>
                  <CardText>
                    <img className="rounded-circle can-click" width="60" src={item.instagram_business_account.profile_picture_url} />
                    <small>
                      <strong> (@{item.instagram_business_account.username}) </strong>{item.instagram_business_account.name}
                    </small>
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )
      }
    </>
  );
}

export default InstagramProfilePageList;
