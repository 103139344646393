//import AuthForm, { STATE_LOGIN } from 'components/AuthForm';
import React, { FormEvent, useState, useContext } from 'react';
import { Card, Col, Row } from 'reactstrap';
import logo200Image from '../assets/img/logo/logo_200.png';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { Context } from '../context/AuthContext';

export default function AuthPage() {
  const[email, setEmail] = useState('');
  const[password, setPassword] = useState('');
  const { handleLogin } = useContext(Context);
  
  function handleSubmit(event) {
    event.preventDefault();
    handleLogin(email, password);
  }

  return (
    <Row
      style={{
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Col md={6} lg={4}>
        <Card body>
          <Form onSubmit={handleSubmit}>

            <div className="text-center pb-4">
              <img
                src={logo200Image}
                className="rounded"
                style={{ width: 60, height: 60, cursor: 'pointer' }}
                alt="logo"
              />
            </div>

            <FormGroup>
              <Label for="username">Email</Label>
              <Input
                type="email"
                placeholder="Digite o seu email"
                required
                onChange={event => setEmail(event.target.value)}
              />
            </FormGroup>


            <FormGroup>
              <Label for="password">Senha</Label>
              <Input
                type="password"
                required
                placeholder="Digite a sua senha"
                onChange={event => setPassword(event.target.value)}
              />
            </FormGroup>

            <FormGroup check>
              <Label check>
                <Input type="checkbox" /> Lembrar-me
              </Label>
            </FormGroup>

            <hr />
            <Button
              size="lg"
              className="bg-gradient-theme-left border-0"
              block
              onClick={handleSubmit}>
              Login
            </Button>
            <div className="text-center pt-1">
              <h6>
                <a href="/forgot">
                  Esqueci Minha Senha
                </a>                
              </h6>
            </div>
          </Form>
        </Card>
      </Col>
    </Row>
  );
  
}









