import Page from '../components/Page';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import ApiUser from '../services/users/userServices'

export default function MyProfilePage () {

  const user = JSON.parse(localStorage.getItem('user'));
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [password, setPassword] = useState('');
  

  const handleStore = async (event) => {
    
    event.preventDefault();

    if (name === '') {
      return toast.error('🦄 Os campo nome é obrigatório! ', { theme: "colored" });
    }
    
    const response = await ApiUser.updateMe(name, password);
    if (response.error) {
      toast.error('🦄 Hummmm! ' + response.error, { theme: "colored" });
    } else {
      toast.success('🐈 Oba! ' + response.message, { theme: "colored" });
      localStorage.setItem('user', JSON.stringify(response.user));
    }
  }

  useEffect(() => {
    document.getElementById('name').value = user.name;
    document.getElementById('email').value = user.email;
  }, []);

  return (
    <Page
      className="DashboardPage"
    >

      <Row>
        <Col xl={12} lg={12} md={12}>
          <Card>
            <CardHeader>Meus Dados</CardHeader>
            <CardBody>
              <Form onSubmit={handleStore}>
                <FormGroup>
                  <Col sm={{ size: 6}}>
                    <Label for="exampleEmail">Seu Nome:</Label>
                    <Input
                      type="text"
                      id="name"
                      onChange={event => setName(event.target.value)}
                    />
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col sm={{ size: 6}}>
                    <Label for="exampleEmail">Email:</Label>
                    <Input
                      type="email"
                      id="email"
                      placeholder="seu@email.com"
                      readonly="readonly"
                      disabled="disabled"
                    />
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col sm={{ size: 3}}>
                    <Label for="examplePassword">Senha</Label>
                    <Input
                      type="password"
                      id="password"
                      placeholder="Sua Senha"
                      onChange={event => setPassword(event.target.value)}
                    />
                  </Col>
                </FormGroup>
                {/* <FormGroup>
                  <Col sm={{ size: 3}}>
                    <Label for="exampleFile">File</Label>
                    <Input type="file" name="file" />
                    
                  </Col>
                </FormGroup> */}
                <FormGroup >
                  <Col sm={{ size: 2 }}>
                    <Button
                      block
                      color="primary"
                      onClick={handleStore}>
                      Salvar
                    </Button>
                  </Col>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    
    </Page>
  );

}
