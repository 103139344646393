import React from 'react';

import { Navbar, Nav, NavItem } from 'reactstrap';

const Footer = () => {

  const d = new Date();
  let year = d.getFullYear();

  return (
    <Navbar>
      <Nav navbar>
        <NavItem>
          { year } Academia da Matilha, Desenvolvido por <a target="_blank" href="https://bleepdigital.com.br">Bleep Digital</a>
        </NavItem>
      </Nav>
    </Navbar>
  );
};

export default Footer;
