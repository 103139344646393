
import React, { useState, useEffect } from 'react';
//import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import {
  Card,
  CardBody,
  CardText,
  Col,
  Row
} from 'reactstrap';
import { FaInstagram } from 'react-icons/fa';

const SocialMediaChoice = ({
  getData,
  ...restProps
}) => {

  const [confirmData, setConfirmData] = useState('');

  const responseData = () => {
    setConfirmData('instagram');
  }

  useEffect(() => {
    getData(confirmData);
  }, [confirmData]);

  return (
    <>
      <Row>
        <Col md={2} sm={2} xs={2} className="mb-3" />
        <Col md={8} sm={8} xs={8} className="mb-3">
          <Card>
            <CardBody>
              <CardText>
                <small>
                  <strong>Atenção: </strong>Para poder vincular o seu <br /><em><FaInstagram /> Instagram</em>, por favor clique no ícone abaixo.
                </small>
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={3} sm={3} xs={3} className="mb-3" />
        <Col md={6} sm={6} xs={6} className="mb-3">
          <Card onClick={responseData} className="can-click">
            <CardBody>
              <CardText>
                <FaInstagram /> Instagram
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default SocialMediaChoice;
