import logo200Image from '../../assets/img/logo/logo_200.png';
import sidebarBgImage from '../../assets/img/sidebar/sidebar-0.jpg';
import React from 'react';
import {
  MdDashboard,
  MdExtension,
  MdKeyboardArrowDown,
  MdPages,
  MdViewCarousel,
} from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import {
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import bn from '../../utils/bemnames';
import { toast } from 'react-toastify';

const user = JSON.parse(localStorage.getItem('user'));
let typePermissions = {};

if (!user || !user.types) {
  toast.error('🦄 Olá! Nosso sistema passou por uma atualização recente e será necessário efetuar um novo login para prosseguir!', { theme: "colored" });
  const token = localStorage.getItem('token');
  if (token && token.length > 0) {
    localStorage.removeItem('token');
    window.location.href = '/';
  }
}else{
  const typePermissionsString = user.types.type_permissions;
  typePermissions = JSON.parse(typePermissionsString);
}


const sidebarBackground = { backgroundImage: `url("${sidebarBgImage}")`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', };

const profileContents = [
  { role:'meus-dados', to: '/meus-dados', name: 'Meus Dados', exact: false, Icon: MdViewCarousel, },
  { role:'meu-endereco', to: '/meu-endereco', name: 'Meu Endereço', exact: false, Icon: MdViewCarousel, },
  { role:'meus-pets', to: '/meus-pets', name: 'Meus Pets', exact: false, Icon: MdViewCarousel, },
];

const navItems = [
  { to: '/', name: 'dashboard', exact: true, Icon: MdDashboard },
  { to: '/media-kit', name: 'mediakit', exact: true, Icon: MdExtension },
  { to: '/redes-sociais', name: 'redes sociais', exact: true, Icon: MdExtension },
]

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  state = {
    isOpenProfile: false,
  };

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];
      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  render() {
    return (
      <aside className={bem.b()} data-image={sidebarBgImage}>
        <div className={bem.e('background')} style={sidebarBackground} />
        <div className={bem.e('content')}>
          <Navbar>
            <span className="navbar-brand d-flex">
              <img
                src={logo200Image}
                width="60"
                
                className="pr-2"
                alt=""
              />
              <span className="text-white">
                Academia <br/> da Matilha
              </span>
            </span>
          </Navbar>
          <Nav vertical>
            {navItems.map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e('nav-item')}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  className="text-uppercase"
                  tag={NavLink}
                  to={to}
                  activeClassName="active"
                  exact={exact}
                >
                  <Icon className={bem.e('nav-item-icon')} />
                  <span className="">{name}</span>
                </BSNavLink>
              </NavItem>
            ))}
            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Profile')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdPages className={bem.e('nav-item-icon')} />
                  <span className="">Perfil</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenProfile
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenProfile}>
              {profileContents.map(({ role, to, name, exact, Icon }, index) => (
                typePermissions.hasOwnProperty(role) &&
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>
          </Nav>
        </div>
      </aside>
    );
  }
}

export default Sidebar;
