import React, { useState } from 'react';
import noMidia from '../../assets/img/components/no-photo-available.png';
import {
  Modal,
  ModalBody,
  Row,
  Col,
  Button
} from 'reactstrap';
import ApiFacebook from '../../services/users/facebookGraphApiServices'
import { toast } from 'react-toastify';
import Typography from '../Typography';
import { FaRegWindowClose } from 'react-icons/fa';
import './styles.css';

const InstagramGetMedias = ({
  getData,
  width,
  height,
  token,
  profileId,
  imageUrl,
  ...restProps
}) => {

  const [instaModal, setInstaModal] = useState(false);
  const [instaMidias, setInstaMidias] = useState([]);
  const [instaNextMidias, setInstaNextMidias] = useState([]);
  const [midiaFile, setMidiaFile] = useState(imageUrl !== '' ? true : false);
  const [midiaUrl, setMidiaUrl] = useState(imageUrl !== '' ? imageUrl : noMidia);

  function toogleInstaModal() {
    if (!instaModal) {
      setInstaModal(true);
    } else {
      setInstaModal(false);
    }
  };

  const getMidias = async (profileId, token) => {
    const response = await ApiFacebook.getInstagramMidias(profileId, token);
    if(!response){
      return toast.error('🦄 Hummmm! Não conseguimos comunicação com o instagram, por favor tente novamente mais tarde!', { theme: "colored" });
    }

    if(response.error){
      return toast.error('🦄 Hummmm! Não conseguimos comunicação com o instagram, por favor tente novamente mais tarde!', { theme: "colored" });
    }

    const midias = response.data.filter(midia => (midia.media_type !== 'VIDEO'));

    setInstaMidias(midias);
    setInstaNextMidias(response.paging.next);
    toogleInstaModal();
    
  }

  const appendMedias = async (next) => {
    const response = await ApiFacebook.showInstagramMidias(next);
    if(!response){
      return toast.error('🦄 Hummmm! Não conseguimos comunicação com o instagram, por favor tente novamente mais tarde!', { theme: "colored" });
    }

    if(response.error){
      return toast.error('🦄 Hummmm! Não conseguimos comunicação com o instagram, por favor tente novamente mais tarde!', { theme: "colored" });
    }

    const midias = response.data.filter(midia => (midia.media_type !== 'VIDEO'));

    setInstaMidias(instaMidias.concat(midias));
    setInstaNextMidias(response.paging.next);
    
    
  }

  const selectMidia = async (response) => {
    getData(response);
    setMidiaFile(true);
    setMidiaUrl(response.media_url);
    toogleInstaModal();
  }

  const removeImage = async (response) =>{

    setMidiaFile(false);
    setMidiaUrl(noMidia);
    getData({ 'media_url':'' });

  }

  return (        
    <>
      <Row>
        <Col>
          {midiaFile === true &&
            <FaRegWindowClose
              className="closeStyle"
              onClick={() => removeImage()}
            />
          }
        </Col>
      </Row>
      <Row>
        <Col><img src={midiaUrl} width={width} height={height} className="imgStyle" onClick={() => getMidias(profileId, token)} className="can-click" /></Col>
      </Row>
        
        
      
      <Modal
        isOpen={instaModal}
        toggle={toogleInstaModal}
        size="lg"
        style={{ maxWidth: '800px', width: '100%' }}
      >
        <ModalBody>
          <Row>
            <center>
              <Typography className="text-secondary" type="h2">Escolha a Mídia</Typography>
            </center>
          </Row>
          <Row>
            {instaMidias.map((item) =>
                <Col key={item.id} sm={3} md={3} xs={3} className="mb-3 mt-3" >
                  <center>
                  <img src={item.media_url} width="100%" className="instaImgStyle"/>
                    <Button size="sm" className="mt-1" onClick={() => selectMidia(item)}>
                      <small>
                        Selecionar Midia
                      </small>
                    </Button>
                  </center>
                </Col>
              )
            }
          </Row>
        { instaNextMidias !== '' && 
          <Row>
            <Col sm={12} md={21} xs={12} className="mb-3 mt-3" >
              <center>
                <Button size="sm" className="mt-1" onClick={() => appendMedias(instaNextMidias)}>
                  <small>
                    Carregar mídias
                  </small>
                </Button>
              </center>
            </Col>
          </Row>
        }
        </ModalBody>
      </Modal>
    </>
  );
}

export default InstagramGetMedias;
