const BASE_API = process.env.REACT_APP_ROUTE_API;
const token = localStorage.getItem('token');
export default {
  getRacesBySpecie: async (specie) => {
    try {
      const req = await fetch(`${BASE_API}specie/`+specie+`?token=` + token, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      });
      const json = await req.json();
      return json;
    } catch (error) {
      return(error);
    }
  },
  getSpecies: async () => {
    try {
      const req = await fetch(`${BASE_API}species?token=` + token, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      });
      return await req.json();
    } catch (error) {
      return (error);
    }
  },
};