import React, { FormEvent, useState } from 'react';
import { Card, Col, Row } from 'reactstrap';
import logo200Image from '../assets/img/logo/logo_200.png';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import ApiUser from '../services/users/userServices'
import { toast } from 'react-toastify';

export default function ForgotPasswordPage() {
  const[email, setEmail] = useState('');
  const[message, setMessage] = useState('');
    
  async function handleSubmit(event) {
    event.preventDefault();
    if (email.length === 0){
      return toast.error('🦄 Por favor informe o seu email para recuperar a senha! ', { theme: "colored" });
    }
    
    const response = await ApiUser.resetPassword(email);

    if(response.success){
      toast.success('🐈 Oba! ' + response.success, { theme: "colored" });
      setMessage('Seu email foi enviado com sucesso! Dentro de instantes você receberá um email em '+email+' com a sua nova senha.');
      setEmail('');
    }else{
      toast.error('🦄 Hummmm! ' + response.error, { theme: "colored" });
    }
  }

  return (
    <Row
      style={{
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Col md={6} lg={4}>
        <Card body>
          <Form onSubmit={handleSubmit}>

            <div className="text-center pb-4">
              <img
                src={logo200Image}
                className="rounded"
                style={{ width: 60, height: 60, cursor: 'pointer' }}
                alt="logo"
              />
            </div>

            <FormGroup>
              <Label for="username">Informe seu Email</Label>
              <Input
                type="email"
                placeholder="Digite o seu email"
                required
                onChange={event => setEmail(event.target.value)}
              />
            </FormGroup>

            <hr />

            <Button
              size="lg"
              className="bg-gradient-theme-left border-0"
              block
              onClick={handleSubmit}>
              Enviar Email
            </Button>
                        
            <div className="text-center pt-1">
              <h6>ou</h6>
              <h6>
                <a href="/login">
                  Efetuar Login
                </a>                
              </h6>
              <br/>
              <h6><strong>{ message }</strong></h6>
            </div>
          </Form>
        </Card>
      </Col>
    </Row>
  );
  
}









