import Page from '../components/Page';
import PageSpinner from '../components/PageSpinner';
import SocialNetworkChoice from '../components/SocialNetworkChoice';
import FacebookInstagramPermissions from '../components/FacebookInstagramPermissions';
import InstagramProfilePageList from '../components/InstagramProfilePageList';
import FacebookButonLogin from '../components/FacebookButonLogin';
import React, { useEffect, useState} from 'react';
import { toast } from 'react-toastify';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Table,
  CardImg
} from 'reactstrap';
import { FaMedium, FaTrash } from 'react-icons/fa';
import ApiUserSocialNetwork from '../services/users/userSocialNetworkServices'
import ApiFacebook from '../services/users/facebookGraphApiServices'

export default function SocialNetworkPage () {

  const [blockPage, setBlockPage] = useState(true);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('Escolha uma Rede Social para vincular');
  const [socialNetworkChoiceShow, setSocialNetworkChoiceShow] = useState(true);
  const [socialNetworkData, setSocialNetworkData] = useState('');
  const [facebookInstagramPermissionsShow, setFacebookInstagramPermissionsShow] = useState(false);
  const [instagramProfilePageListShow, setInstagramProfilePageListShow] = useState(false);
  const [instagramData, setInstagramData] = useState({});
  const [socialNetworks, setSocialNetworks] = useState([]);
  const [socialNetworkId, setSocialNetworkId] = useState('');
  const [validTokenInstagram, setValidTokenInstagram] = useState(false);

  function toogleModal() {
    if (!modal) {
      setModal(true);
    } else {
      setModal(false);
    }
  };

  const getSocialNetworks = async () => {
    const token = await ApiUserSocialNetwork.getNetworkToken('facebook');
    if(token){
      const response = await ApiUserSocialNetwork.getSocialNetworks(token.access_token, token.valid_token);
      if (response === 'error') {
        return toast.error('🦄 Hummmm! ' + response.error, { theme: "colored" });
      }
      setValidTokenInstagram(token.valid_token);
      setSocialNetworks(response);
    }
    setBlockPage(false);
  }

  const responseFacebook = async (response) => {
    if (response.status && response.status ==='unknown'){
      toast.error('🦄 Poxa... O Facebook está indisponível para a autenticação no momento, por favor tente novamente mais tarde! ', { theme: "colored" });
    }else{
      const pages = await ApiFacebook.getInstagramBusinessAccounts(response.id, response.accessToken);
      setInstagramData(pages);
      setFacebookInstagramPermissionsShow(false);
      setLoading(true);
      const sn = await ApiUserSocialNetwork.refreshToken(response);
      setSocialNetworkId(sn.token.id);
      setTimeout(() => {
        setModalTitle('Selecione o perfil que deseja adicionar:');
        setLoading(false);
        setInstagramProfilePageListShow(true);
      }, 1000);
    }
  }

  const handleSocialProfileStore = async(data) => {
    setLoading(true);
    if (!data) {
      toast.error('🦄 Hummm... Houve um problema de comunicação com os nossos servidores, por favor tente novamente mais tarde!', { theme: "colored" });
    } else {
      const dataProfile = { social_network_id: socialNetworkId,
                            social_network_name: socialNetworkData, 
                            social_profile_name: data.instagram_business_account.name,
                            social_profile_username: data.instagram_business_account.username, 
                            social_profile_id: data.instagram_business_account.id, 
                            social_profile_avatar: data.instagram_business_account.profile_picture_url,
                            access_token: data.access_token };
      const response = await ApiUserSocialNetwork.saveSocialNetwork(dataProfile);
      if (response.success) {
        toast.success('🐈 Oba! ' + response.message, { theme: "colored" });
        getSocialNetworks();
        toogleModal();
      } else {
        toast.error('🦄 Hummmm! ' + response.message, { theme: "colored" });
      }
    }
    setLoading(false);
  }

  const handleDeleteProfile = async(social_profile_id) => {
    const response = await ApiUserSocialNetwork.deleteProfile(social_profile_id);
    if (response.success) {
      toast.success('🐈 Oba! ' + response.message, { theme: "colored" });
      getSocialNetworks();
    } else {
      toast.error('🦄 Hummmm! ' + response.message, { theme: "colored" });
    }
  }

  const refreshToken = async (response) => {
    if (response.status && response.status === 'unknown') {
      toast.error('🦄 Poxa... O Facebook está indisponível para a autenticação no momento, por favor tente novamente mais tarde! ', { theme: "colored" });
    } else {
      ApiUserSocialNetwork.refreshToken(response);
      toast.success('Seu token foi atualizado! ', { theme: "colored" });
      getSocialNetworks();
    }
  }

  useEffect(() => {
    if (socialNetworkData!==''){
      setSocialNetworkChoiceShow(false);
      setLoading(true);
      setTimeout(() => { 
        setModalTitle('Verifique se você tem o que precisa para realizar o vínculo:');
        setLoading(false);
        setFacebookInstagramPermissionsShow(true);
       }, 1000);
    }
  }, [socialNetworkData]);

  useEffect(() => {
    setModalTitle('Escolha uma Rede Social para vincular');
    setSocialNetworkData('');
    setSocialNetworkChoiceShow(true);
    setInstagramProfilePageListShow(false);
    setFacebookInstagramPermissionsShow(false);
  }, [modal]);

  useEffect(() => {
    getSocialNetworks();
  }, []);

  return (
    <Page
      className="DashboardPage"
    >
      <BlockUi tag="div" blocking={blockPage} message="Carregando suas Redes Sociais" color="primary">
        <Row>
          <Col>
            <Card className="mb-3">
              <CardHeader>
                <Row>
                  <Col sm={{ size: 10 }}>
                    <FaMedium /> Redes Sociais
                  </Col>
                  <Col sm={{ size: 2 }}>
                    <Button
                      block
                      color="primary"
                      size="sm"
                      onClick={toogleModal}
                    >
                      Cadastrar Rede Social
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <Card body>
                      <Table responsive={true} hover>
                        <tbody>
                        {
                          socialNetworks.map((item) =>
                              
                            <tr key={item.id}>
                              <td>  
                                <CardImg
                                  className="card-img-left rounded-circle ml-2 mt-2"
                                  src={item.profile_picture_url || item.social_profile_avatar}
                                  style={{ width: 'auto', height: 100 }}
                                />
                                @{item.social_profile_username || item.social_profile_link}
                              </td>
                              <td>
                                <FaTrash
                                  color="#4c0870"
                                  size="2em"
                                  title="Excluir Rede Social"
                                  className="can-click"
                                  onClick={() => handleDeleteProfile(item.social_profile_id || item.id)}
                                />
                              </td>
                              <td>
                                {!validTokenInstagram &&
                                  <FacebookButonLogin
                                    getData={(response) => refreshToken(response)}
                                  />
                                }
                              </td>
                            </tr>       
                          )
                        }
                        </tbody>
                      </Table>
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </BlockUi>
      <Modal
        isOpen={modal}
        toggle={toogleModal}
        className="">
          <ModalHeader toggle={toogleModal}><center>{modalTitle}</center></ModalHeader>
          <ModalBody>

            {loading && <PageSpinner color="secondary"/>}

            {socialNetworkChoiceShow &&
              <SocialNetworkChoice
                getData={(socialNetworkData) => setSocialNetworkData(socialNetworkData)}
              /> }

            {facebookInstagramPermissionsShow &&
              <FacebookInstagramPermissions
                getData={(response) => responseFacebook(response)}
              />}

            {instagramProfilePageListShow &&
              <InstagramProfilePageList
                pages={instagramData} 
                getProfile={(data) => handleSocialProfileStore(data)}
              />}
          </ModalBody>
      </Modal>    
    </Page>
  );

}
