
import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import {
  Button,
  Col,
  Row
} from 'reactstrap';
import { FaInstagram, FaFacebook } from 'react-icons/fa';

const FacebookInstagramPermissions = ({
  getData,
  ...restProps
}) => {

  const responseFacebook = async (response) => {
    getData(response);
  }

  return (
    <>
      
      <Row>
        <Col md={12} sm={12} xs={12} className="mb-3" >
          <center>
            <FaInstagram /> + <FaFacebook />
          </center>
        </Col>
        <Col md={2} sm={2} xs={2} className="mb-3" />
        <Col md={8} sm={8} xs={8} className="mb-3">
          <p>
            Confira se você tem todos os requisitos necessários para realizar o vínculo com o Facebook.<br/>
          </p>
          <hr />
          <ul>
            <li>Você precisa ter uma conta no Facebook</li>
            <li>A sua conta no Instagram deve estar configurada como Business ou Creator</li>
            <li>Você deve possuir uma página no Facebook e atrelar sua conta do Instagram a ela</li>
            <li>Você precisa ativar todas as permissões de acesso</li>
          </ul>          
          <FacebookLogin
            appId="1077570872987816"
            size="small"
            callback={responseFacebook}
            disableMobileRedirect={true}
            scope="email,pages_show_list,instagram_basic,pages_read_engagement,public_profile,instagram_manage_insights"
            render={renderProps => (
              <Button color="primary" onClick={renderProps.onClick}>
                Ok vamos prosseguir
              </Button>
            )}
          />
        </Col>
      </Row>
    </>
  );
}

export default FacebookInstagramPermissions;
