const BASE_API = process.env.REACT_APP_ROUTE_API;

const token = localStorage.getItem('token');
const user = JSON.parse(localStorage.getItem('user'));

export default {
  checkToken: async () => {
    try {
      const req = await fetch(`${BASE_API}/auth/refresh`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token: token })
      });
      const json = await req.json();
      return json;
    } catch (error) {
      console.log(error);
    }
  },
  me: async (token) => {
    try {
      const req = await fetch(`${BASE_API}auth/me?token=` + token, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
      });
      const json = await req.json();
      return json;
    } catch (error) {
      console.log(error);
    }
  },
  login: async (email, password) => {
    try {
      const req = await fetch(`${BASE_API}auth/login`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: email, password: password })
      });
      const json = await req.json();
      return json;
    } catch (error) {
      console.log(error);
    }
  },
  resetPassword: async (email) => {
    try {
      const req = await fetch(`${BASE_API}auth/reset-password`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: email })
      });
      
      const json = await req.json();
      return json;
    } catch (error) {
      console.log(error);
    }
  },
  updateMe: async (name, password) => {
    try {
      const req = await fetch(`${BASE_API}user-store`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name: name, password: password, id: user.id, token: token })

      });
      const json = await req.json();
      return json;
    } catch (error) {
      console.log(error);
    }
  } 
};