import React, { useState, useContext } from 'react';
import Avatar from '../Avatar';
import { UserCard } from '../Card';
import { Context } from '../../context/AuthContext';
import userImage from '../../assets/img/logo/logo_200.png';
import { MdClearAll, MdExitToApp, } from 'react-icons/md';
import { Button, ListGroup, ListGroupItem, Nav, Navbar, NavItem, NavLink, Popover, PopoverBody, } from 'reactstrap';

import bn from '../../utils/bemnames';

const bem = bn.create('header');

export default function Header() {
  
  const user = JSON.parse(localStorage.getItem('user'));
  const [isOpenUserCardPopover, setIsOpenUserCardPopover] = useState(false);
  const { handleLogout } = useContext(Context);
  
  function toggleUserCardPopover() {
    if (!isOpenUserCardPopover){
      setIsOpenUserCardPopover(true)
    }else{
      setIsOpenUserCardPopover(false)
    }
  }

  function handleSidebarControlButton(event){
    event.preventDefault();
    event.stopPropagation();
    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  }

  return (
    <Navbar light expand className={bem.b('bg-white')}>
      <Nav navbar className="mr-2">
        <Button outline onClick={handleSidebarControlButton}>
          <MdClearAll size={25} />
        </Button>
      </Nav>
      <Nav navbar className={bem.e('nav-right')}>
        <NavItem>
          <NavLink id="Popover2">
            <Avatar
              src={ user.avatar !== '' ? user.avatar : userImage }
              onClick={toggleUserCardPopover}
              alt={ user.name }
              className="can-click"
            />
          </NavLink>
          <Popover
            placement="bottom-end"
            isOpen={isOpenUserCardPopover}
            toggle={toggleUserCardPopover}
            target="Popover2"
            className="p-0 border-0"
            style={{ minWidth: 250 }}
          >
            <PopoverBody className="p-0 border-light">
              <UserCard
                title={user.name}
                subtitle={user.email}
                className="border-light"
              >
                <ListGroup flush>
                  <ListGroupItem tag="button" action className="border-light">
                    <MdExitToApp /> <a href="#" onClick={() => handleLogout()}>Sair</a>
                  </ListGroupItem>
                </ListGroup>
              </UserCard>
            </PopoverBody>
          </Popover>
        </NavItem>
      </Nav>
    </Navbar>
  );
  
}