import Page from '../components/Page';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import ApiAdress from '../services/adresses/adressServices'
import ApiUserAdress from '../services/users/userAdressServices'

export default function MyAdressPage () {

  const [blockPage, setBlockPage] = useState(true);
  const [cep, setCep] = useState([]);
  const [logradouro, setLogradouro] = useState([]);
  const [bairro, setBairro] = useState([]);
  const [numero, setNumero] = useState([]);
  const [complemento, setComplemento] = useState([null]);
  const [state, setState] = useState();
  const [city, setCity] = useState();
  
  const getMyAdress = async() => {
    const response = await ApiUserAdress.getMyAdress();
    setState(response.state || '');
    setCity(response.city || '');
    setCep(response.zipcode || '');
    setLogradouro(response.address || '');
    setBairro(response.neighborhood || '');
    setNumero(response.number || '');
    setComplemento(response.complement || '');
  }
  
  const handleStoreAdress = async (event) => {
    event.preventDefault();
    if (cep.length < 1 || logradouro.length < 1 || numero.length < 1 || state.length < 1 || city.length < 1){
      return toast.error('🦄 Os campos Cep, Logradouro, Numero, Cidade e Estado são obrigatórios! ', { theme: "colored" });
    }
    if (cep.length > 9){
      return toast.error('🦄 O campos Cep deve ter no máximo 9 caracteres! ', { theme: "colored" });
    }
    const response = await ApiUserAdress.updateMyAdress(state,city,cep,logradouro,numero, bairro, complemento);
    
    if(response.success){
      toast.success('🐈 Oba! ' + response, { theme: "colored" });
      localStorage.setItem('adress', JSON.stringify(response.adress));
    }else{
      toast.error('🦄 Hummmm! ' + response, { theme: "colored" });
    }
  }

  const handleCepSearch = async () => {
    if(cep.length < 8 || cep.length > 9){
      return;
    }
      
    try {
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
      const data = await response.json();

      if (response.ok) {
        toast.success('🐈 Carregando endereço...', { theme: "colored" });
        setLogradouro(data.logradouro)
        setState(data.uf)
        setCity(data.localidade)
        setBairro(data.bairro)
      } else {
        return toast.error('🦄 Erro ao buscar o endereço! ', { theme: "colored" });
      }
    } catch (error) {
      return toast.error('🦄 Erro ao buscar o endereço! ', { theme: "colored" });
    }
  };

  useEffect(() => {
    getMyAdress()
  }, []);

  return (
    <Page
      className="DashboardPage"
    >
        <Row>
          <Col xl={12} lg={12} md={12}>
            <Card>
              <CardHeader>Meu Endereço</CardHeader>
              <CardBody>
                <Form onSubmit={handleStoreAdress}>
                  <FormGroup>
                    <Row>
                      <Col sm={{ size: 2}}>
                        <Label for="state">Cep</Label>
                        <Input
                          type="text"
                          value={cep}
                          onChange={event => setCep(event.target.value)}
                          onKeyUp={event => handleCepSearch(event.target.value)}
                          />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup >
                    <Row>
                      <Col sm={{ size: 10}}>
                        <Label for="state">Logradouro</Label>
                        <Input
                          type="text"
                          value={logradouro}
                          onChange={event => setLogradouro(event.target.value)}
                          />
                      </Col>
                      <Col sm={{ size: 2}}>
                        <Label for="state">Número</Label>
                        <Input
                          type="text"
                          value={numero}
                          onChange={event => setNumero(event.target.value)}
                          />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                    <Col sm={{ size: 1}}>
                        <Label for="state">Estado</Label>
                        <Input 
                          type="text" 
                          id="state" 
                          value={state} 
                          onChange={event => setState(event.target.value)}
                          />
                      </Col>
                      <Col sm={{ size: 7}}>
                      <Label for="city">Cidade</Label>
                        <Input 
                          type="text" 
                          id="city" 
                          value={city} 
                          onChange={event => setCity(event.target.value)}
                          />
                      </Col>
                      <Col sm={{ size: 4}}>
                        <Label for="state">Bairro</Label>
                        <Input
                          type="text"
                          value={bairro}
                          onChange={event => setBairro(event.target.value)}
                          />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col sm={{ size: 12}}>
                        <Label for="state">Complemento</Label>
                        <Input
                          type="text"
                          value={complemento}
                          onChange={event => setComplemento(event.target.value)}
                          />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup >
                    <Col sm={{ size: 2}}>
                      <Button
                        block
                        color="primary"
                        onClick={handleStoreAdress}>
                        Salvar
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>

    </Page>
  );
}
