import Page from '../components/Page';
import React, {useState, useEffect} from 'react';
import InputMask from 'react-input-mask';
import { 
  Card, 
  CardBody, 
  CardHeader, 
  Col, 
  Row, 
  Table, 
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
  Label,
  FormGroup,
  Form
} from 'reactstrap';
import ApiUserPets from '../services/users/userPetServices';
import ApiPets from '../services/pets/petServices';
import { toast } from 'react-toastify';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

export default function MyPetsPage () {

  const [blockPage, setBlockPage] = useState(true);
  const [pets, setPets] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('Cadastrar meu Pet');
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [genre, setGenre] = useState('');
  const [postage, setPostage] = useState('');
  const [age, setAge] = useState('');
  const [agePeriod, setAgePeriod] = useState('');
  const [weight, setWeight] = useState('');
  const [breed, setBreed] = useState('');
  const [races, setRaces] = useState([]);
  const [specie, setSpecie] = useState('');  
  const [hasPostage, setHasPostage] = useState(false);  
  const [species, setSpecies] = useState([]);
 
  function toogleModal(item){
    if(!modal){
      setId(item.id ? item.id : '');
      setName(item.name ? item.name : '');
      setGenre(item.genre ? item.genre : '');
      setPostage(item.postage ? item.postage : '');
      setBreed(item.breed_id ? item.breed_id : '');
      setAge(item.age ? item.age : '');
      setAgePeriod(item.age_period ? item.age_period : '');
      let editWeight = '';
      if (item.weight){
        editWeight = '0' + item.weight.toFixed(2);
      }
      setWeight(editWeight)
      setSpecie(item.specie_id ? item.specie_id : '');
      setModalTitle(item.id ? 'Editar meu Pet' : 'Cadastrar meu Pet');
      setModal(true);
    }else{
      setModal(false);
    }
  };

  const handleStore = async (event) => {
    event.preventDefault();
    if (name === '' || genre === '' || breed === '' || age === '' || species === '' || agePeriod === ''){
      return toast.error('🦄 Hummmm! Favor preencher os dados obrigatórios!' , { theme: "colored" });
    }
    if (postage === '' && hasPostage === true) {
      return toast.error('🦄 Hummmm! Por favor preencha todos os dados obrigatórios!', { theme: "colored" });
    }
    
    let petWeight = weight.replace(' KG', '');
        petWeight = petWeight.replace('_', '');
        
    const dataPet = { id: id, name: name, genre: genre, postage: postage, breed_id: breed, age: age, age_period: agePeriod, specie_id: specie, weight: petWeight}
    
    const response = await ApiUserPets.updatePet(dataPet);
    
    if (response.success) {
      toast.success('🐈 Oba! ' + response.message, { theme: "colored" });
      getPets();
      toogleModal();
    } else {
      toast.error('🦄 Hummmm! ' + response.message, { theme: "colored" });
    }
    
  }

  const handleDelete = async (id) => {
    
    const response = await ApiUserPets.deletePet(id);

    if (response.success) {
      toast.success('🐈 Oba! ' + response.message, { theme: "colored" });
      getPets();
    } else {
      toast.error('🦄 Hummmm! ' + response.message, { theme: "colored" });
    }

  }

  const getPets = async () => {
    const response = await ApiUserPets.getUserPets();
    if (response === 'error') {
      toast.error('🦄 Hummmm! ' + response.error, { theme: "colored" });
    } 
    setPets(response);
    setBlockPage(false);
  }

  const getSpecies = async () => {
    const response = await ApiPets.getSpecies();
    if (response === 'error') {
      toast.error('🦄 Hummmm! ' + response.error, { theme: "colored" });
    }
    setSpecies(response);
  }

  const handleGetRacesBySpecie = async () => {
    const response = await ApiPets.getRacesBySpecie(specie);
    if (response === 'error') {
      toast.error('🦄 Hummmm! ' + response.error, { theme: "colored" });
    } else {
      setRaces(response.races);
      setHasPostage(response.has_postage);
    }
  }
  
  useEffect(() => {
    getSpecies();
    getPets();
  }, []);

  useEffect(() => {
    if (specie > 0) {
      handleGetRacesBySpecie();
    }
  }, [specie]);

  return (
    <Page
      className="DashboardPage"
    >
      <BlockUi tag="div" blocking={blockPage} message="Carregando seus pets" color="primary">
        <Row>
          <Col>
            <Card className="mb-3">
              <CardHeader>
                <Row>
                <Col sm={{ size: 10 }}>
                  🐩 Meus Pets
                </Col>
                <Col sm={{ size: 2 }}>
                  <Button
                    block
                    color="primary"
                    size="sm"
                    onClick={toogleModal}
                  >
                    Cadastrar Meu Pet
                  </Button>
                </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <Card body>
                      <Table responsive={true} hover>
                        <thead>
                          <tr>
                            <th>Nome</th>
                            <th>Raça</th>
                            <th>Espécie</th>
                            <th>Gênero</th>
                            <th>Porte</th>
                            <th>Idade</th>
                            <th>Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            pets.map((item) =>
                              <tr key={item.id}>
                                <td>{item.name}</td>
                                <td>{item.breed}</td>
                                <td>{item.specie}</td>
                                <td>{item.genre}</td>
                                <td>{item.postage}</td>
                                <td>{item.age} {item.age_period}</td>
                                <td>
                                  <Button color="primary" onClick={() => toogleModal(item)}>
                                    <FaEdit />
                                  </Button>{' '}
                                  <Button color="secondary" onClick={() => handleDelete(item.id)}>
                                    <FaTrashAlt />
                                  </Button>
                                </td>
                              </tr>
                            )
                          }
                        </tbody>
                      </Table>
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </BlockUi>
      <Modal
        isOpen={modal}
        toggle={toogleModal}
        className="">
        <Form onSubmit={handleStore}>
          <ModalHeader toggle={toogleModal}>{ modalTitle }</ModalHeader>
          <ModalBody>
              <FormGroup>
                <Row>
                  <Col sm={{ size: 12}}>
                  <Label for="name">Nome do seu Pet (*):</Label>
                    <Input
                      type="text"
                      value={name}
                      onChange={event => setName(event.target.value)}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col sm={{ size: 6 }}>
                    <Label for="species">Espécie do seu Pet (*):</Label>
                    <Input type="select" id="specie_id" value={specie} onChange={event => setSpecie(event.target.value)}>
                      <option value="0">::Selecione::</option>
                      {
                        species.map((itemSpecie) =>
                          <option key={itemSpecie.id} value={itemSpecie.id}>{itemSpecie.specie}</option>
                        )
                      }
                    </Input>
                  </Col>
                  <Col sm={{ size: 6 }}>
                    <Label for="breed">Raça do seu Pet (*):</Label>
                    <Input type="select" id="breed_id" value={breed} onChange={event => setBreed(event.target.value)}>
                      <option value="0">::Selecione::</option>
                      {
                        races.map((itemBreed) =>
                          <option key={itemBreed.id} value={itemBreed.id}>{itemBreed.breed}</option>
                        )
                      }
                  </Input>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col sm={{ size: 6 }}>
                    <Label for="genre">Gênero do seu Pet (*):</Label>
                    <Input type="select" id="genre" value={genre} onChange={event => setGenre(event.target.value)}>
                      <option value="">::Selecione::</option>
                      <option value="Fêmea">Fêmea</option>
                      <option value="Macho">Macho</option>
                    </Input>
                  </Col>
                  { hasPostage &&
                    <Col sm={{ size: 6 }}>
                    <Label for="postage">Porte do seu Pet (*):</Label>
                      <Input type="select" id="postage" value={postage} onChange={event => setPostage(event.target.value)}>
                        <option value="">::Selecione::</option>
                        <option value="Mini">Mini</option>
                        <option value="Pequeno">Pequeno</option>
                        <option value="Médio">Médio</option>
                        <option value="Grande">Grande</option>
                        <option value="Gigante">Gigante</option>
                      </Input>
                    </Col>
                  }
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col sm={{ size: 7 }}>
                    <Label for="age">Idade do seu Pet (*):</Label>
                    <Row>
                      <Col>
                        <Input
                          type="number"
                          value={age}
                          onChange={event => setAge(event.target.value)}
                        />
                      </Col>
                      <Col>
                        <Input type="select" id="age_period" value={agePeriod} onChange={event => setAgePeriod(event.target.value)}>
                          <option value="">::Selecione::</option>
                          <option value="Anos">Ano(s)</option>
                          <option value="Meses">Mes(es)</option>
                        </Input>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={{ size: 5 }}>
                    <Label for="age">Peso do seu Pet (*):</Label>
                    <InputMask 
                      className="form-control"
                      mask="99.99 KG"
                      onChange={event => setWeight(event.target.value)}
                      value={weight}
                    />
                  </Col>
                </Row>
              </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleStore}>
              Salvar
            </Button>{' '}
            <Button color="secondary" onClick={toogleModal}>
              Cancelar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </Page>
  );

}
