import Page from '../components/Page';
import React, { useEffect, useState } from 'react';
import FacebookButonLogin from '../components/FacebookButonLogin';
import InstagramGetMedias from '../components/InstagramGetMidias/InstagramGetMedias';
import InstagramGetBusinessAccount from '../components/InstagramGetBusinessAccount';
import { toast } from 'react-toastify';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Typography from '../components/Typography';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Button,
  Modal,
  ModalBody,
  FormGroup,
  Label,
  Input,
  Table,
  CardImg,
  Form
} from 'reactstrap';
import { 
  FaMedium, 
  FaInstagram, 
  FaRegWindowClose,
  FaHome,
  FaInfoCircle,
  FaUsers,
  FaTty,
  FaAt,
  FaPhone,
  FaRegSun,
  FaCopyright
} from 'react-icons/fa';
import ApiMidiaKit from '../services/midiakit/midiaKitServices';
import ApiUserSocialNetwork from '../services/users/userSocialNetworkServices';

export default function MediaKitPage() {

  const [block, setBlock] = useState(false);
  const [blockPage, setBlockPage] = useState(true);
  const [modal, setModal] = useState(false);
  const [warningModal, setWarningModal] = useState(false);
  const [socialNetworks, setSocialNetworks] = useState([]);
  const [validTokenInstagram, setValidTokenInstagram] = useState('');
  const [profileId, setProfileId] = useState('');
  const [username, setUsername] = useState('');
  const [profile, setProfile] = useState('');
  const [description, setDescription] = useState('');
  const [whoDoes, setWhoDoes] = useState('');
  const [imageAbout, setImageAbout] = useState([]);
  const [imageWhoDoes, setImageWhoDoes] = useState([]);
  const [imageWhoDoesPreview, setImageWhoDoesPreview] = useState('');
  const [showImageWhoDoes, setShowImageWhoDoes] = useState(1);
  const [frontImage, setFrontImage] = useState([]);
  const [coverImage, setCoverImage] = useState([]);
  const [brands, setBrands] = useState([]);
  const [brandsTitle, setBrandsTitle] = useState([]);
  const [imageBrands, setImageBrands] = useState([]);
  const [partners, setPartners] = useState([]);
  const [contactEmail, setContactEmail] = useState([]);
  const [analytics, setAnalytics] = useState([]);
  const [cities, setCities] = useState([]);
  const [sex, setSex] = useState([]);
  const [age, setAge] = useState([]);
  const [insights, setInsights] = useState([]);

  let inputRef;

  const user = JSON.parse(localStorage.getItem('user'));
  const typePermissionsString = user.types.type_permissions;
  const typePermissions = JSON.parse(typePermissionsString);
  
  function toogleModal() {
    if (!modal) {
      setModal(true);
    } else {
      setModal(false);
    }
  };

  function toogleWarningModal() {
    if (!warningModal) {
      setWarningModal(true);
    } else {
      setWarningModal(false);
    }
  };

  const getSocialNetworks = async () => {
    const token = await ApiUserSocialNetwork.getNetworkToken('facebook');
    
    const response = await ApiUserSocialNetwork.getSocialNetworks(token.access_token, token.valid_token);
    if (response === 'error') {
      return toast.error('🦄 Hummmm! ' + response.error, { theme: "colored" });
    }
    setValidTokenInstagram(token.access_token);
    setSocialNetworks(response);
    
    if (token.error) {
      toogleWarningModal();
    }
    setBlockPage(false);
  }

  const getMidiaKit = async (profile_id) => {
    
    const midiakit = await ApiMidiaKit.getMidiaKitData(profile_id);   
    
    let brand = [];
    let brandTitle = [];
    let partners = [];
    let cities = [];
    let age = [];

    if (midiakit.mk !== undefined) {
      setProfile(midiakit.mk.name);
      setDescription(midiakit.mk.description);
      setWhoDoes(midiakit.mk.who_does);
      setContactEmail(midiakit.mk.contact_email);
      setAnalytics(midiakit.mk.show_analytics);
    }
    
    if (midiakit.mkPhoto !== undefined){
      setCoverImage(midiakit.mkPhoto.filter(data => (data.type === 'coverImage')));
      setFrontImage(midiakit.mkPhoto.filter(data => (data.type === 'frontImage')));
      setImageAbout(midiakit.mkPhoto.filter(data => (data.type === 'imageAbout')));
      setImageWhoDoes(midiakit.mkPhoto.filter(data => (data.type === 'imageWhoDoes')));
    }
    
    if (midiakit.mkBrand !== undefined){
      setImageBrands(midiakit.mkBrand);
      midiakit.mkBrand.map((item,index) => brand[index] = item.description)
      setBrands(brand);
      midiakit.mkBrand.map((item, index) => brandTitle[index] = item.title)
      setBrandsTitle(brandTitle);
    }
    
    if (midiakit.mkPartner !== undefined) {
      midiakit.mkPartner.map((item,index) => partners[index] = item )
      setPartners(partners);
    }

    if (midiakit.mkAudienceSex !== undefined) {
      setSex(midiakit.mkAudienceSex);
    }

    if (midiakit.mkAudienceCityPercent !== undefined) {
      midiakit.mkAudienceCityPercent.map((item,index) => cities[index] = item )
      setCities(cities);
    }

    if (midiakit.mkAudienceAge !== undefined) {
      midiakit.mkAudienceAge.map((item,index) => age[index] = item )
      setAge(age);
    }

    if (midiakit.insights !== undefined) {
      setInsights(midiakit.insights);
    }

  }

  const refreshToken = async (response) => {
    if (response.status && response.status === 'unknown') {
      toast.error('🦄 Poxa... O Facebook está indisponível para a autenticação no momento, por favor tente novamente mais tarde! ', { theme: "colored" });
    } else {
      ApiUserSocialNetwork.refreshToken(response);
      toast.success('Seu token foi atualizado! ', { theme: "colored" });
      getSocialNetworks();
    }
  }

  const store = async () => {
    
    setBlock(true);
    
    const data = {social_profile_id: profileId,
                  name: profile,
                  description: description,
                  who_does: whoDoes,
                  contact_email: contactEmail.length > 0 ? contactEmail : null,
                  show_analytics : analytics.length > 0 ? analytics : 1
                }
    const response = await ApiMidiaKit.storeMidiaKit(data);

    const dataImage = {social_profile_id: profileId,
                       imageAbout: imageAbout,
                       imageWhoDoes: imageWhoDoes,
                       frontImage: frontImage,
                       coverImage: coverImage}

    const responseImage = await ApiMidiaKit.storeImageMidiaKit(dataImage);

    const dataBrands = {social_profile_id: profileId,
                        title: brandsTitle,
                        brands: brands,
                        imageBrands: imageBrands}
    const responseBrands = await ApiMidiaKit.storeBrandsMidiaKit(dataBrands);

    const dataPartners = {
      social_profile_id: profileId,
      partners: partners
    }
    const responsePartners = await ApiMidiaKit.storePartnersMidiaKit(dataPartners);
    

    if (response.success) {
      toast.success('🐈 Oba! ' + response.message, { theme: "colored" });
    } else {
      toast.error('🦄 Hummmm! ' + response.error, { theme: "colored" });
    }    
    setBlock(false);
  }

  function createEditMidiaKit(profile_id, username){
    getMidiaKit(profile_id);
    setProfileId(profile_id);
    setUsername(username);
    toogleModal();
  }
  
  function updateFrontImage(imageData, position) {
    let obj = frontImage;
    obj[position] = imageData;
    setFrontImage(obj);
  }

  function updateCoverImage(imageData, position) {
    let obj = coverImage;
    obj[position] = imageData;
    setCoverImage(obj);
  }

  function updateAboutImage(imageData,position){
    let obj = imageAbout;
    obj[position] = imageData;
    setImageAbout(obj);
  }

  function updateWhoDoesImage(imageData, position) {
    let obj = imageWhoDoes;
    obj[position] = imageData;
    setImageWhoDoes(obj);
  }

  function renderBrands (maxBrands) {
    const brandsItems = [];
    for (let i = 0; i < maxBrands; i++) {
      brandsItems.push(
        <Row key={i}>
          <Col md={3} sm={3} xs={3} className="mb-3">
            <InstagramGetMedias
              getData={(response) => updateImageBrands(response, i)}
              width="160"
              height="160"
              token={validTokenInstagram}
              profileId={profileId}
              imageUrl={imageBrands[i] !== undefined ? imageBrands[i].photo : ''}
            />
          </Col>
          <Col md={9} sm={9} xs={9} className="mb-3">
            <Input
              placeholder="Título"
              type="text"
              value={brandsTitle[i] !== undefined ? brandsTitle[i] : ''}
              onChange={updateBrandsTitle(i)}
              required
              className="form-control"
              maxLength="30"
            />
            <br />
            <Input
              type="textarea"
              rows="4"
              value={brands[i] !== undefined ? brands[i] : ''}
              onChange={updateBrands(i)}
              maxLength="650"
            />
            {brands[i] ? brands[i].length : '0'} de 650 caracteres
          </Col>
        </Row>
      );
    }

    return brandsItems;
  };

  function updateImageBrands(imageData, position) {
    let obj = imageBrands;
    obj[position] = imageData;
    setImageBrands(obj);

  }

  const updateBrands = index => e =>{
    let newArr = [...brands]; 
    newArr[index] = e.target.value; 
    setBrands(newArr);
  }

  const updateBrandsTitle = index => e => {
    let newArr = [...brandsTitle];
    newArr[index] = e.target.value;
    setBrandsTitle(newArr);
  }

  function renderPartners(maxPartners) {
    const partnersItems = [];
    const colsPerRow = 5;
  
    for (let i = 0; i < maxPartners; i++) {
      partnersItems.push(
        <Col md={2} sm={2} xs={2} className="m-1" key={i}>
          <InstagramGetBusinessAccount
            width="120"
            height="120"
            token={validTokenInstagram}
            profileId={profileId}
            partner={partners[i] !== undefined ? partners[i] : ''}
            getPartner={(response) => updatePartners(response, i)}
          />
        </Col>
      );
  
      if ((i + 1) % colsPerRow === 0 || i === maxPartners - 1) {
        // Render a <Row> for every colsPerRow elements or if it's the last element.
        partnersItems.push(<Row key={`row-${i / colsPerRow}`}>{partnersItems.splice(-colsPerRow)}</Row>);
      }
    }
  
    return partnersItems;
  }

  function updatePartners(partnerData, position) {
    let pData='';
    if (partnerData !== undefined){
       pData = {"followers_count": partnerData.followers_count,
                "media_count": partnerData.media_count,
                "photo": partnerData.profile_picture_url,
                "name": partnerData.name,
                "username": partnerData.username};    
    }
    let obj = partners;
    obj[position] = pData;
    setPartners(obj);
  }

  function changeImage(e){

    let files = e.target.files;
    let reader = new FileReader();
    
    if (reader.readAsDataURL) { 
      reader.readAsDataURL(files[0]); 
    }
    else if (reader.readAsDataurl) { 
      reader.readAsDataurl(files[0]); 
    }
    
    reader.onload=(e)=>{
      const response ={'media_url': e.target.result, 'media_from':'file_upload', 'name':files[0].name};
      updateWhoDoesImage(response, 0);
    }

    reader.onloadend = () => {
      setImageWhoDoesPreview(URL.createObjectURL(files[0]));
    } 

  }

  function removerWhoDoesImage(){
    const response = { 'media_url': '', 'photo':'' };
    updateWhoDoesImage(response, 0);
    setShowImageWhoDoes(0);
    return toast.warning('Atenção! Para concluir a remoção da imagem, clique no botão salvar!', { theme: "colored" });
  }

  function removerWhoDoesImagePreview() {
    setImageWhoDoesPreview('');
  }

  useEffect(() => {
    getSocialNetworks();
  }, []);

  return (
    <Page
      className="DashboardPage"
    >
      <BlockUi tag="div" blocking={blockPage} message="Carregando dados seu MidiaKit" color="primary">
        <Row>
          <Col>
            <Card className="mb-3">
              <CardHeader>
                <Row>
                  <Col sm={{ size: 10 }}>
                    <FaMedium /> Mídia Kit
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Table responsive={true} hover>
                  <tbody>
                    { socialNetworks.map((item) =>
                        <tr key={item.id}>
                          <td>
                            <CardImg
                                className="card-img-left rounded-circle ml-2 mt-2 mb-2"
                                src={item.profile_picture_url || item.social_profile_avatar}
                                style={{ width: 'auto', height: 100 }}
                              />
                            <br/>
                            <strong>
                              <FaInstagram size="1em" /> {item.social_profile_username || item.social_profile_link}
                            </strong>
                          </td>
                          <td>
                            {validTokenInstagram === '' ?
                              <Col md={12} sm={12} xs={12}>
                                <FacebookButonLogin
                                  getData={(response) => refreshToken(response)}
                                />
                              </Col>
                              :
                              <>
                                <Col md={3} sm={3}>
                                  <a href={`https://midiakit.academiadamatilha.com.br/${item.social_profile_username || item.social_profile_link}`} target="_blank">
                                    <Button style={{ width: '100%', margin:'10px 0' }} color="outline-primary" sty>
                                      Ver
                                    </Button>
                                  </a>
                                </Col>
                                <Col md={3} sm={3}>
                                  <Button style={{ width: '100%', margin: '10px 0' }} color="outline-secondary" onClick={() => createEditMidiaKit(item.social_profile_id || item.id, item.social_network_name || item.username)}>
                                    Editar
                                  </Button>
                                </Col>
                              </>
                            }
                          </td>
                        </tr>
                      )
                    }
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </BlockUi>
      <Modal
        isOpen={modal}
        toggle={toogleModal}
        size="lg" 
        style={{ maxWidth: '900px', width: '100%' }}>
        <ModalBody>
            <BlockUi tag="div" blocking={block} message="Estamos atualizado seu MidiaKit" color="primary">
              <Form className="needs-validation">
                <Tabs>
                  <FaRegWindowClose
                    color="#4c0870"
                    size="2em"
                    className="can-click float-right"
                    onClick={toogleModal}
                  />
                  <TabList>
                    {typePermissions.hasOwnProperty('home') &&
                      <Tab> <FaAt /> Nome Perfil </Tab>}
                    {typePermissions.hasOwnProperty('home') &&
                      <Tab> <FaHome /> Capa </Tab>}
                    {typePermissions.hasOwnProperty('sobre') &&
                      <Tab> <FaInfoCircle /> {user.types.title_about} </Tab>}
                    {typePermissions.hasOwnProperty('quem-faz') &&
                      <Tab> <FaUsers /> {user.types.title_who_does} </Tab>}
                    {typePermissions.hasOwnProperty('parceiros') &&
                      <Tab> <FaTty/> {user.types.title_partners} </Tab>}
                    {typePermissions.hasOwnProperty('cases') &&
                      <Tab> <FaCopyright /> {user.types.title_brands} </Tab>}
                    {typePermissions.hasOwnProperty('analise') &&
                      <Tab> <FaRegSun /> Análise </Tab>}
                    {typePermissions.hasOwnProperty('contatos') &&
                      <Tab> <FaPhone /> Contato </Tab>}
                  </TabList>
                  {typePermissions.hasOwnProperty('home') &&
                    <TabPanel>
                      <Row>
                        <Col md={12} sm={12} xs={12} className="mb-3" />
                        <Col md={1} sm={1} xs={1} className="mb-3" />
                        <Col md={10} sm={10} xs={10} className="mb-3">
                          <Typography className="text-secondary" type="h2">Seu @</Typography>
                          Comece informando o nome do seu perfil
                        </Col>
                      </Row>
                      <FormGroup>
                        <Row>
                          <Col md={1} sm={1} xs={1} className="mb-3" />
                          <Col md={10} sm={10} xs={10} className="mb-3">
                            <Label for="profile"><strong>Qual o nome do seu perfil?</strong></Label>
                            <Input
                              type="text"
                              value={profile}
                              onChange={event => setProfile(event.target.value)}
                              required
                              className="form-control"
                              maxLength="30"
                            />
                            {profile ? profile.length : '0'} de 30 caracteres
                          </Col>
                        </Row>
                      </FormGroup>
                      <Row>
                        <Col md={1} sm={1} xs={1} className="mb-3" />
                        <Col md={10} sm={10} xs={10} className="mb-3">
                          <Typography className="text-secondary" type="h4">DICA DE PREENCHIMENTO</Typography>
                          Se seu usuário é <br/>
                          <strong>@matilhabrasil</strong> <br/><br/>
                          Escreva:<br/>
                          Matilha Brasil
                        </Col>
                      </Row>
                      
                    </TabPanel>}
                    {typePermissions.hasOwnProperty('home') &&
                    <TabPanel>
                      <Row>
                        <Col md={12} sm={12} xs={12} className="mb-3" />
                        <Col md={1} sm={1} xs={1} className="mb-3" />
                        <Col md={10} sm={10} xs={10} className="mb-3">
                          <Typography className="text-secondary" type="h2">Capa</Typography>
                          Que tal fazer uma capa lindona com o nome de seu perfil no Instagram?
                        </Col>
                      </Row>
                      <Row>
                        <Col md={1} sm={1} xs={1} className="mb-3" />
                        <Col md={10} sm={10} xs={10}>
                          <Col md={12} sm={12} xs={12} className="mb-3">
                            <Label for="description"><strong>Selecione uma imagem para ser exibida em destaque no seu MídiaKit:</strong></Label>
                          </Col>
                          <InstagramGetMedias
                            getData={(response) => updateFrontImage(response, 0)}
                            width="120"
                            height="120"
                            token={validTokenInstagram}
                            profileId={profileId}
                            imageUrl={frontImage[0] !== undefined ? frontImage[0].photo : ''}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={1} sm={1} xs={1} className="mb-3" />
                        <Col md={10} sm={10} xs={10} className="mb-3">
                          <Label for="description"><strong>Selecione quais imagens irão compor a capa do seu MídiaKit:</strong></Label>
                        </Col>
                        <Col md={1} sm={1} xs={1} className="mb-3" />
                        <Col>
                          <InstagramGetMedias
                            getData={(response) => updateCoverImage(response, 0)}
                            width="120"
                            height="120"
                            token={validTokenInstagram}
                            profileId={profileId}
                            imageUrl={coverImage[0] !== undefined ? coverImage[0].photo : ''}
                          />
                        </Col>
                        <Col>
                          <InstagramGetMedias
                            getData={(response) => updateCoverImage(response, 1)}
                            width="120"
                            height="120"
                            token={validTokenInstagram}
                            profileId={profileId}
                            imageUrl={coverImage[1] !== undefined ? coverImage[1].photo : ''}
                          />
                        </Col>
                        <Col>
                          <InstagramGetMedias
                            getData={(response) => updateCoverImage(response, 2)}
                            width="120"
                            height="120"
                            token={validTokenInstagram}
                            profileId={profileId}
                            imageUrl={coverImage[2] !== undefined ? coverImage[2].photo : ''}
                          />
                        </Col>
                        <Col>
                          <InstagramGetMedias
                            getData={(response) => updateCoverImage(response, 3)}
                            width="120"
                            height="120"
                            token={validTokenInstagram}
                            profileId={profileId}
                            imageUrl={coverImage[3] !== undefined ? coverImage[3].photo : ''}
                          />
                        </Col>
                        <Col>
                          <InstagramGetMedias
                            getData={(response) => updateCoverImage(response, 4)}
                            width="120"
                            height="120"
                            token={validTokenInstagram}
                            profileId={profileId}
                            imageUrl={coverImage[4] !== undefined ? coverImage[4].photo : ''}
                            />
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col>
                          <InstagramGetMedias
                            getData={(response) => updateCoverImage(response, 5)}
                            width="120"
                            height="120"
                            token={validTokenInstagram}
                            profileId={profileId}
                            imageUrl={coverImage[5] !== undefined ? coverImage[5].photo : ''}
                          />
                        </Col>
                        <Col>
                          <InstagramGetMedias
                            getData={(response) => updateCoverImage(response, 6)}
                            width="120"
                            height="120"
                            token={validTokenInstagram}
                            profileId={profileId}
                            imageUrl={coverImage[6] !== undefined ? coverImage[6].photo : ''}
                          />
                        </Col>
                        <Col>
                          <InstagramGetMedias
                            getData={(response) => updateCoverImage(response, 7)}
                            width="120"
                            height="120"
                            token={validTokenInstagram}
                            profileId={profileId}
                            imageUrl={coverImage[7] !== undefined ? coverImage[7].photo : ''}
                          />
                        </Col>
                        <Col>
                          <InstagramGetMedias
                            getData={(response) => updateCoverImage(response, 8)}
                            width="120"
                            height="120"
                            token={validTokenInstagram}
                            profileId={profileId}
                            imageUrl={coverImage[8] !== undefined ? coverImage[8].photo : ''}
                          />
                        </Col>
                        <Col>
                          <InstagramGetMedias
                            getData={(response) => updateCoverImage(response, 9)}
                            width="120"
                            height="120"
                            token={validTokenInstagram}
                            profileId={profileId}
                            imageUrl={coverImage[9] !== undefined ? coverImage[9].photo : ''}
                          />
                        </Col>
                      </Row>
                      <hr/>
                      <Row>
                        <Col>
                          <InstagramGetMedias
                            getData={(response) => updateCoverImage(response, 10)}
                            width="120"
                            height="120"
                            token={validTokenInstagram}
                            profileId={profileId}
                            imageUrl={coverImage[10] !== undefined ? coverImage[10].photo : ''}
                          />
                        </Col>
                        <Col>
                          <InstagramGetMedias
                            getData={(response) => updateCoverImage(response, 11)}
                            width="120"
                            height="120"
                            token={validTokenInstagram}
                            profileId={profileId}
                            imageUrl={coverImage[11] !== undefined ? coverImage[11].photo : ''}
                          />
                        </Col>
                        <Col>
                          <InstagramGetMedias
                            getData={(response) => updateCoverImage(response, 12)}
                            width="120"
                            height="120"
                            token={validTokenInstagram}
                            profileId={profileId}
                            imageUrl={coverImage[12] !== undefined ? coverImage[12].photo : ''}
                          />
                        </Col>
                        <Col>
                          <InstagramGetMedias
                            getData={(response) => updateCoverImage(response, 13)}
                            width="120"
                            height="120"
                            token={validTokenInstagram}
                            profileId={profileId}
                            imageUrl={coverImage[13] !== undefined ? coverImage[13].photo : ''}
                          />
                        </Col>
                        <Col>
                          <InstagramGetMedias
                            getData={(response) => updateCoverImage(response, 14)}
                            width="120"
                            height="120"
                            token={validTokenInstagram}
                            profileId={profileId}
                            imageUrl={coverImage[14] !== undefined ? coverImage[14].photo : ''}
                          />
                        </Col>
                      </Row>
                    </TabPanel>}
                  {typePermissions.hasOwnProperty('sobre') &&
                    <TabPanel>
                      <Row>
                        <Col md={12} sm={12} xs={12} className="mb-3" />
                        <Col md={1} sm={1} xs={1} className="mb-3" />
                        <Col md={10} sm={10} xs={10} className="mb-3">
                          <Typography className="text-secondary" type="h2">Sobre</Typography>
                          Essa é a hora de vender bem o seu trabalho! Fale para uma possivel marca anunciante sobre seu perfil no Instagram num textinho objetivo de até 650 caracteres.
                        </Col>
                      </Row>
                      <FormGroup>
                        <Row>
                          <Col md={1} sm={1} xs={1} className="mb-3" />
                          <Col md={10} sm={10} xs={10} className="mb-3">
                            <Label for="description"><strong>Qual é a proposta do seu perfil?</strong></Label>
                            <Input
                              type="textarea"
                              value={description}
                              onChange={event => setDescription(event.target.value)}
                              maxLength="650"
                            />
                            {description ? description.length : '0'} de 650 caracteres
                          </Col>
                        </Row>
                      </FormGroup>
                      <Row>
                        <Col sm={12} md={12} xs={12} className="mb-3" />
                        <Col md={1} sm={1} xs={1} className="mb-3" />
                        <Col md={10} sm={10} xs={10} className="mb-3">
                          <Typography className="text-secondary" type="h4">DICA DE PREENCHIMENTO</Typography>
                          Imagine seu perfil como uma pessoa:<br/>
                          Quem ele é? A que se propõe? Qual o objetivo dele? Qual a razão de sua existência?
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} md={12} xs={12} className="mb-3" />
                        <Col md={1} sm={1} xs={1} className="mb-3" />
                        <Col md={6} sm={6} xs={6} className="mb-3">
                          <Typography className="text-secondary" type="h4">Qual imagem representa seu perfil?</Typography>
                          Escolha uma foto para ilustrar o texto que você acabou de escrever sobre quem é seu perfil.<br/>
                        </Col>
                        <Col md={5} sm={5} xs={5}>
                          <InstagramGetMedias
                            getData={(response) => updateAboutImage(response, 0)}
                            width="180"
                            height="180"
                            token={validTokenInstagram}
                            profileId={profileId}
                            imageUrl={imageAbout[0] !== undefined ? imageAbout[0].photo : ''}
                          />
                        </Col>
                      </Row>
                    </TabPanel>}
                  {typePermissions.hasOwnProperty('quem-faz') &&
                    <TabPanel>
                    <Row>
                      <Col md={12} sm={12} xs={12} className="mb-3" />
                      <Col md={1} sm={1} xs={1} className="mb-3" />
                      <Col md={10} sm={10} xs={10} className="mb-3">
                        <Typography className="text-secondary" type="h2">Quem Faz?</Typography>
                          Quem faz o perfil de @{username}? Mostre a um potencial anunciante quem é você e o que você faz na vida. Por que seu histórico pessoal pode influenciar positivamente no interesse de uma marca por você?<br/>
                        Alguém te ajuda na administração do perfil?
                      </Col>
                    </Row>
                    <FormGroup>
                      <Row>
                        <Col md={1} sm={1} xs={1} className="mb-3" />
                        <Col md={10} sm={10} xs={10} className="mb-3">
                            <Label for="description"><strong>Quem faz o perfil de @{username}?</strong></Label>
                          <Input
                            type="textarea"
                            value={whoDoes}
                            onChange={event => setWhoDoes(event.target.value)}
                            maxLength="650"
                          />
                          {whoDoes ? whoDoes.length : '0'} de 650 caracteres
                        </Col>
                      </Row>
                    </FormGroup>
                    <Row>
                      <Col sm={12} md={12} xs={12} className="mb-3" />
                      <Col md={1} sm={1} xs={1} className="mb-3" />
                      <Col md={10} sm={10} xs={10} className="mb-3">
                        <Typography className="text-secondary" type="h4">DICA DE PREENCHIMENTO</Typography>
                        Quem é você e o que faz da vida?<br/>
                        Você tem alguma característica que o torna único e pode ser aproveitada por campanhas?
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} md={12} xs={12} className="mb-3" />
                      <Col md={1} sm={1} xs={1} className="mb-3" />
                      <Col md={12} sm={12} xs={12} className="mb-3">
                        <Typography className="text-secondary" type="h4">Escolha uma Imagem que te represente</Typography>
                        Escolha nas sua galeria uma foto para ilustrar o texto que você acabou de escrever sobre quem é seu perfil.<br />
                        Ela aparecerá em destaque no seu midia kit.
                      </Col>

                      {imageWhoDoes[0]?.photo != '' && showImageWhoDoes > 0 &&
                        <Col md={4} sm={4} xs={4} className="mb-3">
                          <center>
                            <Button color="secondary" onClick={() => removerWhoDoesImage()} component="span">
                              Remover Imagem
                            </Button>
                            <img src={imageWhoDoes[0]?.photo} style={{ width: '180px', borderRadius: '10px', background: '#dedede', marginTop: '10px' }} />
                          </center>
                        </Col>
                      }
                      <Col md={4} sm={4} xs={4} className="mb-3">
                        <input 
                          accept="image/*" 
                          type="file" 
                          name="file" 
                          onChange={(e) => changeImage(e)}
                          hidden={true}
                          ref={refParam => inputRef = refParam}/>                        
                        <Button color="primary" onClick={() => inputRef.click()} component="span">
                          Carregar Imagem
                        </Button>
                      </Col>
                      <Col md={4} sm={4} xs={4} className="mb-3">
                        { (imageWhoDoesPreview !== '' ) && 
                          <center>
                            <Button color="secondary" onClick={() => removerWhoDoesImagePreview()} component="span">
                              Fechar Preview
                            </Button>
                            <br/>
                            <img src={ imageWhoDoesPreview } style={{ width: '180px', borderRadius:'10px', background: '#dedede' ,marginTop:'10px' }}/>
                          </center>
                        }
                      </Col>
                    </Row>
                    </TabPanel>}
                  {typePermissions.hasOwnProperty('parceiros') &&
                    <TabPanel>
                      <Row>
                        <Col sm={12} md={12} xs={12} className="mb-3" />
                        <Col md={1} sm={1} xs={1} className="mb-3" />
                        <Col md={10} sm={10} xs={10} className="mb-3">
                          <Typography className="text-secondary" type="h2">Parcerias</Typography>
                          Aqui vamos configurar as marcas para as quais você já fez campanha. 
                          Para informar uma marca parceira tanto a sua marca como a marca parceira deve ser uma conta de negócios ou criadora de conteúdo no Instagram, a marca parceira não pode ser uma conta privada. 
                          Selecione a marca informado o nome da mesma sem o <strong>@</strong>. Se você não informar nenhuma marca, não apresentaremos essa área no seu Mídia Kit. 
                          Selecione no máximo 8 parceiros.
                        </Col>
                      </Row>
                      <Row>
                        <Col md={1} sm={1} xs={1} className="mb-3" />
                        <Col md={10} sm={10} xs={10} className="mb-3">
                          <Row>
                            {renderPartners(user.types.max_partners)}
                          </Row>
                        </Col>
                      </Row>
                    </TabPanel>}
                  {typePermissions.hasOwnProperty('cases') &&
                    <TabPanel>
                      <Row>
                        <Col sm={12} md={12} xs={12} className="mb-3" />
                        <Col md={1} sm={1} xs={1} className="mb-3" />
                        <Col md={10} sm={10} xs={10} className="mb-3">
                          <Typography className="text-secondary" type="h2">Cases</Typography>
                          Aqui vamos configurar os seus principais cases de sucesso. Selecione qual foto representa seu melhor trabalho. Depois selecione a marca informado o @ da mesma no Instagram. Se você não selecionar nenhuma foto, não apresentaremos essa área no seu Mídia Kit. Selecione no máximo 3 fotos, sendo uma foto por marca.
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={1} md={1} xs={1} className="mb-3"/>
                        <Col sm={10} md={10} xs={10} className="mb-3">
                          { renderBrands(user.types.max_brands) }
                        </Col>
                      </Row>
                    </TabPanel>}
                  {typePermissions.hasOwnProperty('analise') &&
                    <TabPanel>
                      <Row>
                        <Col md={12} sm={12} xs={12} className="mb-3" />
                        <Col md={1} sm={1} xs={1} className="mb-3" />
                        <Col md={10} sm={10} xs={10} className="mb-3">
                          <Typography className="text-secondary" type="h2">Análise Instagram</Typography>
                          O Instagram constrói os dados deacesso do seu  midiakit
                        </Col>
                      </Row>
                      <FormGroup>
                        <Row>
                          <Col md={1} sm={1} xs={1} className="mb-3" />
                          <Col  md={10} sm={10} xs={10} className="mb-3">
                            <Label for="profile">
                                Basta informar se deseja exibir os dados  gerados pelo instagram no seu mediakit no campo abaixo:
                            </Label>
                            <Input type="select" id="show_analytics" value={analytics} onChange={event => setAnalytics(event.target.value)}>
                              <option value="1">Exibir análise no meu Midiakit</option>
                              <option value="0">Ocultar análise do meu Midiakit</option>
                            </Input>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} sm={12} xs={12} className="mb-3" >
                            <Typography className="text-secondary" type="h4">
                              Público e Audiência
                            </Typography>
                          </Col>
                          <Col md={4} sm={4} xs={4} className="mb-3" >
                            <b>Por Cidade:</b> <br/>
                            {cities.map((item) => (
                              <span key={item.city}>
                                {item.city} - {item.percent} <br/>
                              </span>
                            ))}
                          </Col>
                          <Col md={4} sm={4} xs={4} className="mb-3" >
                            <b>Por Idade:</b> <br/>
                            {age.map((item) => (
                              <span key={item.age}>
                                {item.age} - {item.qtd}<br/>
                              </span>
                            ))}
                          </Col>
                          <Col md={4} sm={4} xs={4} className="mb-3" >
                            <b>Por Gênero:</b><br/>
                              Feminino: {sex.women_percent}%<br/>
                              Masculino: {sex.men_percent}%<br/>
                              Neutro: {sex.undefined_percent}%
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} sm={12} xs={12} className="mb-3" >
                            <Typography className="text-secondary" type="h4">
                              Dados Analíticos do Instagram
                            </Typography>
                          </Col>
                          <Col md={4} sm={4} xs={4} className="mb-3" >
                            <b>Análise de Feedb</b>
                            <br/>{insights.posts_total} Posts
                            <br/>{insights.posts_likes} Curtidas
                            <br/>{insights.posts_comments} Comentários
                            <br/>{insights.posts_views} Visualizações
                            <br/>{insights.posts_reach} Alcance
                            <br/>{insights.posts_saves} Salvos
                            <br/>{insights.posts_engagement} Interações
                            <br/>{insights.posts_engajamento_alcance} % Engajamento/Alcance
                            <br/>{insights.posts_engajamento_seguidores} % Engajamento/Seguidores
                          </Col>
                          <Col md={4} sm={4} xs={4} className="mb-3" >
                            <b>Análise de Reels </b>
                            <br/>{insights.videos_total} Vídeos
                            <br/>{insights.videos_likes} Curtidas
                            <br/>{insights.videos_comments} Comentários
                            <br/>{insights.videos_plays} Reproduções
                            <br/>{insights.videos_reach} Alcance
                            <br/>{insights.videos_saves} Salvos
                            <br/>{insights.videos_shares} Compartilhamentos
                            <br/>{insights.videos_engagement} Interações
                            <br/>{insights.videos_engajamento_alcance} % Engajamento/Alcance
                            <br/>{insights.videos_engajamento_seguidores} % Engajamento/Seguidores
                            <br/>{insights.videos_taxa_retencao} % Taxa de Retenção
                          </Col>
                          <Col md={4} sm={4} xs={4} className="mb-3" >
                            <Row>
                              <Col md={12} sm={12} xs={12} className="mb-3" >
                                <b>Análise de Story</b>
                                <br/>{insights.stories_total} Stories
                                <br/>{insights.stories_tap_back} Taps Back
                                <br/>{insights.stories_replies} Comentários
                                <br/>{insights.stories_views} % Visualizações
                                <br/>{insights.stories_reach} % Alcance
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12} sm={12} xs={12} className="mb-3" >
                                <b>Consolidado</b>
                                <br/>{insights.consolidado_total_alcance} Total de Alcance
                                <br/>{insights.consolidado_total_irv} Total de impressões,
                                <br/>reproduções e visualizações
                                <br/>{insights.consolidado_engajamento_alcance} Engajamento/Alcance
                                <br/>{insights.consolidado_engajamento_seguidores} Engajamento/Seguidores
                              </Col>
                            </Row>
                          </Col>
                        </Row>  
                      </FormGroup>
                    </TabPanel>}
                  {typePermissions.hasOwnProperty('contatos') &&
                    <TabPanel>
                      <Row>
                        <Col md={12} sm={12} xs={12} className="mb-3" />
                        <Col md={1} sm={1} xs={1} className="mb-3" />
                        <Col md={10} sm={10} xs={10} className="mb-3">
                          <Typography className="text-secondary" type="h2">Contato</Typography>
                          Como seus futuros parceiros entrarão em contato com você?
                        </Col>
                      </Row>
                      <FormGroup>
                        <Row>
                          <Col md={1} sm={1} xs={1} className="mb-3" />
                          <Col md={10} sm={10} xs={10} className="mb-3">
                            <Label for="profile">
                                Informe o email da marca, ele ficará disponível no seu midiakit e os emails serão enviados pra ele.<br />
                                Caso você não informe um email válido aqui o seu email de cadastro será utilizado.
                            </Label>
                            <Input
                              type="email"
                              value={contactEmail}
                              onChange={event => setContactEmail(event.target.value)}
                              required
                              className="form-control"
                              maxLength="100"
                            />
                            {contactEmail ? contactEmail.length : '0'} de 100 caracteres
                          </Col>
                        </Row>
                      </FormGroup>
                    </TabPanel>}
                  <Row>
                    <Col sm={12} md={12} xs={12} className="mb-3 mt-3" >
                      <center>
                        <Button color="primary" onClick={store}>
                          Salvar e Continuar
                        </Button>
                      </center>
                    </Col>
                  </Row>
                </Tabs>
              </Form>
            </BlockUi>
        </ModalBody>
      </Modal>  
      <Modal
        isOpen={warningModal}
        toggle={toogleWarningModal}
        size="lg" 
        style={{ maxWidth: '900px', width: '100%' }}>
        <ModalBody>
            <FaRegWindowClose
              color="#4c0870"
              size="2em"
              className="can-click float-right"
              style={{ float: 'right'}}
              onClick={toogleWarningModal}
            />
            <Row>
              <Col md={12} sm={12} xs={12} className="mb-3" />
              <Col md={1} sm={1} xs={1} className="mb-3" />
              <Col md={10} sm={10} xs={10} className="mb-3">
                <Typography className="text-secondary" type="h2">Atenção:</Typography>
                Para editar as imagens do seu MidiaKit é necessário atualizar seu TOKEN <br/>
                clicando em Redes Sociais e depois no botão Atualizar Token.
              </Col>
            </Row>
        </ModalBody>
      </Modal>
    </Page>
  );

}
