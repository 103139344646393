const BASE_API = process.env.REACT_APP_ROUTE_API;

const token = localStorage.getItem('token');
const user = JSON.parse(localStorage.getItem('user'));

export default {
  updateMyAdress: async (state, city, cep, logradouro, numero, bairro, complemento) => {
    try {
      const req = await fetch(`${BASE_API}user-adress`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          state: state,
          city: city,
          zipcode: cep,
          address: logradouro,
          number: numero,
          bairro: bairro,
          complement: complemento,
          user_id: user.id,
          token: token
        })

      });

      // Verificar se a resposta foi bem-sucedida (código de status 2xx)
      if (!req.ok) {
        const errorResponse = await req.json();
        if (errorResponse.errors) {
          const errorMessages = Object.values(errorResponse.errors).flatMap(errors => errors);
          return errorMessages.join('\n');
        } else {
          return errorResponse.message || 'Erro ao processar a requisição';
        }
      }

      const json = await req.json();
      return json.message;
    } catch (error) {
      console.error('Erro:', error.message);
      throw error; // Propagar o erro para quem chamou essa função, se necessário
    }
  },
  getMyAdress: async () => {
    try {
      const req = await fetch(`${BASE_API}user-adress/`+user.id+`?token=`+token, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
      });
      const json = await req.json();
      return json;
    } catch (error) {
      console.log(error);
    }
  } 
};