const BASE_API = process.env.REACT_APP_ROUTE_API;
const token = localStorage.getItem('token');
const user = JSON.parse(localStorage.getItem('user'));

export default {
  getUserPets: async () => {
    try {
      const req = await fetch(`${BASE_API}user-pets/` + user.id + `?token=` + token, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
      });
      const json = await req.json();
      return json;
    } catch (error) {
      console.log(error);
    }
  },
  updatePet: async(dataPet) => {
    try {
      dataPet.user_id = user.id;
      dataPet.token = token;
      const req = await fetch(`${BASE_API}user-pets`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataPet)
      });
      const json = await req.json();
      return json;
    } catch (error) {
      console.log(error);
    }
  },
  deletePet: async(id) => {
    try {
      const req = await fetch(`${BASE_API}user-pets-remover`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({user_id:user.id, id:id, token:token})
      });
      const json = await req.json();
      return json;
    } catch (error) {
      console.log(error);
    }
  }
};