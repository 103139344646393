import React, { useState } from 'react';
import noMidia from '../assets/img/components/no-photo-available.png';
import { DebounceInput } from 'react-debounce-input';
import ApiFacebook from '../services/users/facebookGraphApiServices'
import { toast } from 'react-toastify';

const InstagramGetBusinessAccount = ({
  getPartner,
  width,
  height,
  token,
  profileId,
  partner,
  ...restProps
}) => {

  const [value, setValue] = useState(false);
  const [urlProfile, setUrlProfile] = useState(partner !== '' ? partner.photo : noMidia);

  const searchDebounce = (value, profileId, token) => {
    setValue(value);
    getBusinessAccount(value,profileId, token);
  }

  const getBusinessAccount = async (value,profileId, token) => {

    if(value === '' || value === undefined || value.length === 0){
      getPartner();
      setUrlProfile(noMidia);
    }else{
      const response = await ApiFacebook.getInstagramBusinessAccountInfo(value,profileId, token);
  
      if(response.error){
        switch (response.error.message){
          case 'Invalid user id':
            return toast.error('🦄 O @'+value+' não foi encontrado  no instagram!', { theme: "colored" });
            break;
          default:
            return toast.error('🦄 Hummmm! Não conseguimos comunicação com o instagram, por favor tente novamente mais tarde!', { theme: "colored" });
            break;
        }
      }
  
      if (!response) {
        return toast.error('🦄 Hummmm! Não conseguimos comunicação com o instagram, por favor tente novamente mais tarde!', { theme: "colored" });
      }
  
      getPartner(response.business_discovery);    
      setUrlProfile(response.business_discovery.profile_picture_url);
    }

    
  }

  return (        
    <>
      <DebounceInput
        placeholder="@contaparceira"
        value={partner !== undefined ? partner.username : ''}
        debounceTimeout={1500}
        onChange={event => searchDebounce(event.target.value, profileId, token)}
        className="form-control"
      />
      <br/>
      <img src={ urlProfile } width={width} height={height} style={{ borderRadius:"10px"}}/>
    </>
  );
}

export default InstagramGetBusinessAccount;
