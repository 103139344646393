import React, { useContext } from 'react';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';
import { Context } from './context/AuthContext';
import { STATE_LOGIN, STATE_FORGOT } from './components/AuthForm';
import { EmptyLayout, LayoutRoute, MainLayout } from './components/Layout';
import PageSpinner from './components/PageSpinner';
import AuthPage from './pages/AuthPage';
import DashboardPage from './pages/DashboardPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import MyProfilePage from './pages/MyProfilePage';
import MyAdressPage from './pages/MyAdressPage';
import MyPetsPage from './pages/MyPetsPage';
import MediaKitPage from './pages/MediaKitPage';
import SocialMediaPage from './pages/SocialMediaPage';
import PoliticPage from './pages/PoliticPage';
import './styles/reduction.scss';


function CustomRoute({ isPrivate, isLogin, ...rest }) {
  const { loading, authenticated } = useContext(Context);
  
  if (loading) {
    return <h1>Loading...</h1>;
  }

  if (isPrivate && !authenticated) {
    return <Redirect to="/login" />
  }

  if (isLogin && authenticated){
    return <Redirect to="/" />
  }

  return <LayoutRoute {...rest} />;
}

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

export default function Routes() {
  return (
    <BrowserRouter basename={getBasename()} >
      <Switch>

        <CustomRoute
          exact
          path="/login"
          isLogin
          layout={EmptyLayout}
          component={props => (
            <AuthPage {...props} authState={STATE_LOGIN} />
          )}
        />
        <CustomRoute
          exact
          path="/forgot"
          layout={EmptyLayout}
          component={props => (
            <ForgotPasswordPage {...props} authState={STATE_FORGOT} />
          )}
        />

        <CustomRoute
          exact
          path="/politicas-de-uso"
          layout={EmptyLayout}
          component={props => (
            <PoliticPage {...props} />
          )}
        />

        <React.Suspense fallback={<PageSpinner />}>
          <CustomRoute isPrivate exact path="/" layout={MainLayout} component={DashboardPage} />
          <CustomRoute isPrivate exact path="/meus-dados" layout={MainLayout} component={MyProfilePage} />
          <CustomRoute isPrivate exact path="/meu-endereco" layout={MainLayout} component={MyAdressPage} />
          <CustomRoute isPrivate exact path="/meus-pets" layout={MainLayout} component={MyPetsPage} />
          <CustomRoute isPrivate exact path="/media-kit" layout={MainLayout} component={MediaKitPage} />
          <CustomRoute isPrivate exact path="/redes-sociais" layout={MainLayout} component={SocialMediaPage} />
        </React.Suspense>

      </Switch>
    </BrowserRouter>
  );
}